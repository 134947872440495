import React, {useEffect, useRef, useState, useCallback} from "react";
import general from "../../general";
import generalnew from "../../generalnew";
import {CloseOutlined, RadiusBottomleftOutlined, RightOutlined, ShareAltOutlined,} from '@ant-design/icons';
import {useLocalStorageState, useLockFn, useReactive, useScroll, useSize, useThrottleFn, useUpdateEffect, useMount, useInterval} from "ahooks";
import {Link} from "react-router-dom";
import {StarOutlined, StarFilled, FormOutlined, PlayCircleOutlined, LeftOutlined} from '@ant-design/icons';
import Index from "./detail/Index";
import Gift from "./detail/Gift";
import Server from "./detail/Server";
import Comment from "./detail/Comment";
import Trade from "./detail/Trade";
import Bag from "./detail/Bag";
import Community from "./detail/Community/Community";
import {Swiper, SwiperSlide} from 'swiper/react';
import $, { data } from "jquery";
import Voucher from "./detail/Voucher";
import {CopyToClipboard} from "react-copy-to-clipboard/lib/Component";
import {NavLink} from "../../lib/Navigation";
import VipDownload from "./VipDownload";
import {Player} from 'video-react';
import useActiveState from "../../lib/useActiveState";
import Dialog from "../../lib/Dialog";
import Image from "../../lib/Image";
import {CSSTransition} from "react-transition-group";
import moment from "moment";
import MobileDetect from "mobile-detect";
import useUrlState from "@ahooksjs/use-url-state";
import URI from "urijs";
import {useHistory} from "react-router-dom";
import Iframe from "../general/Iframe";
import '../../css/server.css';
import Wechat from '../wechat/Index'
import { Swiper as Antd_swiper } from 'antd-mobile'
import GameTools from "./gametools/GameTools";
import VersionMask from "../mask/VersionMask";
import Subtitle from "../components/Subtitle";
import Manager from "../components/Manager";

const mobileDetect = new MobileDetect(navigator.userAgent);

// const player = {};
export default props => {
  const {aplus_queue} = window;
  const history = useHistory();
  let [agent, setAgent] = useActiveState('agent');
  const [user, setUser] = useActiveState('user');
  const [udid, setUdid] = useActiveState('udid');
  const [loading, setLoading] = useState(false);
  const [init, setInit] = useState(false);
  const [game, setGame] = useState();
  const [params, setParams] = useUrlState({}, {navigateMode: 'replace'});
  const [weChartImg, setWeChatImg] = useState(false);
  const [url, setGameUrl] = useState();
  const [underUrl, setUnderUrl] = useState();
  const [getIosUrl, setGetIosUrl] = useState([]);
  // const [chanal, setChanal] = useState(['dn6h'])
  const [payUrl, setPayUrl] = useState();
  const [searchHistory, setSearchHistory] = useLocalStorageState('search-history', []);
  const [config, setConfig] = useActiveState('config');
  const [trumpetState, setTrumpetState] = useState(false);//是否有小号状态--领取礼包
  const [topLable, setTopLable] = useState(); //顶部label
  const [bottomLable, setBottomLable] = useState(); //底部label
  const [showTestDown, setShowTestDown] = useState(0);

  const [serverShow,setServerShow] = useState()

  const [randomColorInfo] = useActiveState('randomColorInfo');//info随机字体颜色
  const [infoTextColor, setInfoTextColor] = useState();
  const [open, setOpen] = useState(true);
  const [exclusive_benefit, setexclusive_benefit] = useState([]);

  const [ceshiNumbei] = useActiveState('ceshiNumbei');//ceshi号
  const [interceptState, setInterceptState] = useState(false);//udid拦截弹框显示

  const [customizeState, setCustomizeState] = useState(false);//自定义按钮状态

  const [downThreeState, setDownThreeState] = useState(true);//三个按钮提示状态

  const [osPopState, setOsPopState] = useState(false);//系统弹框判断
  const [alert,setAlert] = useState(false)
  const [gameInfo,setGameInfo] = useState()
  const [gameUserInfo,setGameUserInfo] = useState()
  const [router,setRouter] = useState([
    {
      id: 'index',
      component: Index,
      name: '详情',
    },
    // {
    //   id: 'gift',
    //   component: Gift,
    //   name: '福利',
    //   count: '',
    // },
    // {
    //   id: 'comment',
    //   component: Comment,
    //   name: '评论',
    //   count: '',
    // },
    // {
    //   id: 'server',
    //   component: Server,
    //   name: '开服',
    // },
    {
      id: 'Bag',
      component: Bag,
      name: '礼包',
      count: '',
    },
    // {
    //   id: 'comment',
    //   component: Comment,
    //   name: '评论',
    //   count: '',
    // },
    {
      id: 'comment',
      component: Community,
      name: '社区',
      count: '',
    },
    // {
    //   id: 'trade',
    //   component: Trade,
    //   name: '交易',
    //   count: '',
    // },
  ]);


  useMount(()=>{setInfoTextColor(randomColorInfo[parseInt(Math.random() * 4)])});

  // const [testStatus, setTextStatus] = useState(false);
  // const [vipRouterIndex, setVipRouterIndex] = useState(0);
  // const [temporaryUrl, setTemporaryUrl] = useState(); //新的临时的支付链接
  

  let [key] = useActiveState('key');
  let [app, setApp] = useActiveState('app');
  let [mb] = '' || useActiveState('mb');

  const [colorArray, setColorArray] = useState([]);

  //useEffect(()=>{
    // if(window.location.host == 'webbox.99maiyou.cn') {
    //   let testAgent = params.agent;
    //   setAgent(testAgent ? testAgent : agent);
    // }
  //}, [agent]);
  
  // useEffect(() => {
  //   let testAgent = 'rr2f';
  //   if(window.location.host !== 'webbox.99maiyou.cn') {
  //     general.axios.get('https://apiagent.mlaps0705.com/admin/admin/queryAgentInfo?host=' + window.location.host,{})
  //       .then(res => {
  //         let data = res.data;
          
  //         if(data.code == 1) {
  //           testAgent = data.data.agent??testAgent;
  //         }
  //       })
  //       .catch(err => {
  //       });
  //   } else {
  //     testAgent = params.agent;
  //   }
  //   setAgent(agent ? agent : testAgent);
  //   if(params.agent_status != '3b8n') {
  //     if (!key || app.agent !== agent) {
  //       general.axios.post('/setKeyUdid', {agent})
  //         .then(res => {
  //           let {status, data} = res.data;
  //           if (!status.succeed) {
  //             Dialog.error(status.error_desc);
  //             return;
  //           }
  //           setKey(key = data.key);
  //           setParams({...params, key: data.key});
  //         })
  //         .catch(err => {
  //           Dialog.error(err.message);
  //         });
  //     }
  //   }

  //   if (key && (app.key !== key || !app.udid)) {
  //     general.axios.post('/getKeyUdid', {key})
  //       .then(res => {
  //         let {status, data} = res.data;
  //         if (!status.succeed) {
  //           Dialog.error(status.error_desc);
  //           return;
  //         }
  //         setApp(app = {...app, ...data});
  //       })
  //       .catch(err => {
  //         Dialog.error(err.message);
  //       });
  //   }

  //   if (key) {
  //     aplus_queue.push({
  //       action: 'aplus.setMetaInfo',
  //       arguments: ['openid', key]
  //     });
  //   }
  // }, [agent]);


  const loadAgent =  useLockFn(async () => {
    params.agent && setAgent(agent = params.agent);

    if(window.location.href.indexOf('amp;agent') != '-1') {
      let readurl = window.location.href.replace(/amp;agent/, "agent");
      window.location.href = readurl;
    }

    if(window.location.href.indexOf('amp%3Bagent') != '-1') {
      let readurl = window.location.href.replace(/amp%3Bagent/, "agent");
      window.location.href = readurl;
    }
    // if (!key || app.agent !== agent) {
    //   general.axios.post('/setKeyUdid', {agent})
    //     .then(res => {
    //       let {status, data} = res.data;
    //       if (!status.succeed) {
    //         Dialog.error(status.error_desc);
    //         return;
    //       }
    //       setKey(key = data.key);
    //       setParams({...params, key: data.key});
    //     })
    //     .catch(err => {
    //       Dialog.error(err.message);
    //     });
    // }

    // if (key && (app.key !== key || !app.udid)) {
    //   general.axios.post('/getKeyUdid', {key})
    //     .then(res => {
    //       let {status, data} = res.data;
    //       if (!status.succeed) {
    //         Dialog.error(status.error_desc);
    //         return;
    //       }
    //       setApp(app = {...app, ...data});
    //     })
    //     .catch(err => {
    //       Dialog.error(err.message);
    //     });
    // }
  })

  useEffect(()=> {
    if( props.active && (window.location.host == 'webbox.99maiyou.cn' || window.location.host == 'web.99maiyou.cn' || window.location.host == 'web.milu.com' || window.location.hostname == 'localhost' || window.location.origin == 'http://agent.mlaps0705.com') ) {
      if(params.agent != agent) {
        loadAgent();
      }
    }
  }, [props.active, params.agent])

  // const selTestAgent = useLockFn(async () => {
  //   let testAgent = 'rr2f';
  //   if(window.location.host !== 'webbox.99maiyou.cn') {
  //     // general.axios.get('https://apiagent.mlaps0705.com/admin/admin/queryAgentInfo?host=xpa0.99maiyou.com&source=1',{})
  //     general.axios.get('https://apiagent.mlaps0705.com/admin/admin/queryAgentInfo?host=' + window.location.host,{})
  //       .then(res => {
  //         let data = res.data;
          
  //         if(data.code == 1) {
  //           testAgent = data.data.agent?data.data.agent:testAgent;
  //           setAgent(testAgent);
  //         }
  //       })
  //       .catch(err => {
  //         setAgent(testAgent);
  //       });
  //       loadAgent();
  //   } else {
  //     testAgent = params.agent;
  //     setAgent(testAgent);
  //     loadAgent();
  //   }
  // })

  // console.log(agent);

  const urlLogin = () => {
    // console.log(params);
    if (params.userid && params.username && params.token) {
      // Dialog.info("第1部")
      // if (window.navigator.standalone ** localStorage.getItem('logout')) {
      //   Dialog.info("第2部")
      //   return;
      // }
      // Dialog.info("第3部")
      localStorage.setItem('user_id', params.userid);
      localStorage.setItem('username', params.username);
      localStorage.setItem('token', params.token);

      load_load();
    }
  }

  // console.log(params);

  const load_load = useLockFn(async () => {
    await general.axios.post('/user/user/getMemberInfo')
      .finally(() => {
      })
      .then(response => {
        let {status, data} = response.data;
        if (!status.succeed) {

        } else {
          if (!localStorage.getItem('yx_token')){
            setTimeout(() => {
                PubSub.publish('loginSubscribe',true)
            }, 100);
          }
          localStorage.setItem('yx_account',data.member_info.yx_account)
          localStorage.setItem('yx_token',data.member_info.yx_token)
          setUser({isLogin: true, ...data.member_info});
        }
      })
      .catch(err => {
          Dialog.error(err.message);
        });
  })

  useMount(urlLogin);

  useEffect(() => {
    // console.log(url);
    //各种渠道号(...)
    let parAgent = "rr2f";
    // console.log(user)
    // console.log(user.agent)
    // console.log(agent);
    // let shareAgent = user.isLogin ? user.agent : (agent??parAgent);
    // console.log(window.location.href);
    // let url = "https://" + window.location.host + "/#/game?id="+ params.id;
    // let underUrl = "https://" + window.location.host + "/#/game?id="+ params.id;
    let url = window.location.href;
    let underUrl = window.location.href;
    if(window.location.host == 'webbox.99maiyou.cn' || window.location.host == 'web.99maiyou.cn' || window.location.host == 'web.milu.com') {
      if(params.agent) {
        url = window.location.href
      } else if(props.query.agent) {
        url = window.location.href
      } else {
        url = window.location.href + "&agent=" + agent;
        underUrl = window.location.href + "&agent=" + agent;
      }
    }
    setGameUrl(url);
    setUnderUrl(underUrl);

    // props.setTitle('');
    // props.setOperate(<CopyToClipboard text={url} onCopy={() => Dialog.info('已复制链接')}><ShareAltOutlined/></CopyToClipboard>);
    // props.setOperate(<CopyToClipboard text={url} onCopy={() => Dialog.info('已复制链接')}><img src="/resources/user/share.png" style={{width:'18px'}} className="anticon anticon-share-alt"/></CopyToClipboard>);
  }, [game, params.agent]);

  const [wheelseed, setWheelseed] = useState([]);

  const load_game_info = ()=>{
    if (loading) {
      return;
    }
    // selTestAgent();
    setLoading(true);
    Dialog.loading();
    let data = {};
    if (props.query.id) {
      data.game_id = props.query.id;
    } else if (props.query.mid) {
      data.maiyou_gameid = props.query.mid;
    } else if(params.id){
      data.game_id = params.id;
    } else {
      Dialog.error('缺少参数', 1, () => history.goBack);
      return;
    }

    if(props.query.id == '9457') {
      let url = "/";
      if(window.location.host == 'webbox.99maiyou.cn') {
        if(params.agent) {
          url = "https://webbox.99maiyou.cn/#/?agent=" + params.agent;
        } else {
          url = "https://webbox.99maiyou.cn/#/?agent=" + agent;
        }
      } else if(window.location.host == 'web.99maiyou.cn') {
        if(params.agent) {
          url = "https://web.99maiyou.cn/#/?agent=" + params.agent;
        } else {
          url = "https://web.99maiyou.cn/#/?agent=" + agent;
        }
      }
      window.location.href=url;
      return;
    }

    if(props.query.t) {
      data.t = props.query.t;
    } else if(params.t) {
      data.t = params.t;
    } 

    if(props.query.tt) {
      data.tt = props.query.tt;
    }

    if(!data.game_id) {
      if(!data.maiyou_gameid) {
        Dialog.error('缺少参数', 1, () => history.goBack);
        return;
      }
    }
    general.axios.post('/getGameInfo',data)
    .finally(() => {
      setLoading(false);
      Dialog.close();
    })
    .then(res => {
      let {status, data} = res.data;
      if (!status?.succeed) {
        Dialog.error(status.error_desc);
        if(status.error_desc.indexOf('已下架') !== -1) {
          let url = "/";
          if(window.location.host == 'webbox.99maiyou.cn') {
            if(params.agent) {
              url = "https://webbox.99maiyou.cn/#/?agent=" + params.agent;
            } else {
              url = "https://webbox.99maiyou.cn/#/?agent=" + agent;
            }
          } else if(window.location.host == 'web.99maiyou.cn') {
            if(params.agent) {
              url = "https://web.99maiyou.cn/#/?agent=" + params.agent;
            } else {
              url = "https://web.99maiyou.cn/#/?agent=" + agent;
            }
          } else if(window.location.host == 'web.milu.com') {
            if(params.agent) {
              url = "https://web.milu.com/#/?agent=" + params.agent;
            } else {
              url = "https://web.milu.com/#/?agent=" + agent;
            }
          }

          window.location.href=url;
          // if (general.history.length > 1){
          //   setTimeout(() => {
          //     general.history.goBack()
          //   }, 1000);
          // }else{
            // window.location.href=window.location.origin;
          // }
        }
      } else {
        
        setTopLable(data.top_lable);
        setBottomLable(data.bottom_lable);

        let game = {...data, ...data.game_info};
        console.log('game',game);
        setGameInfo(game)
        if (game.exclusive_benefit.length > 3){
          setexclusive_benefit([
            game.exclusive_benefit[0],
            game.exclusive_benefit[1],
            game.exclusive_benefit[2]
          ])
          setOpen(false)
        }else {
          setexclusive_benefit(game.exclusive_benefit)
          setOpen(true)
        }

        let anyIosDownUrl = game.game_url.ios_url.replace('http://','https://');
        setGetIosUrl(anyIosDownUrl);

        let payUrl = game.supremePayUrl + "&from=react";
        setPayUrl(payUrl);
        if (!init && parseInt(data.kitsCount) > 0) {
          setRouter([...router,{
            id: 'tools',
            component: GameTools,
            name: '工具箱',
          }])
        }
        setInit(true);

        //搜索历史-202207新加
        if(params.source === 'searchHistory') {
          // let history_cover = searchHistory ? searchHistory ? [];
          let history_cover = searchHistory ? searchHistory : [];

          if(!searchHistory.includes(game.game_name)) {
            history_cover.push(game.game_name);
            setSearchHistory(history_cover);
          }
        }

        let wheeltype = '';
        let wheelurl = '';
        if(game.video_url) {
          wheeltype = 'video';
          wheelurl = game.video_url;
        } else {
          wheeltype = 'img-video';
          wheelurl = game.banner_url;
        }

        let wheelspeed = [{
          id: 1,
          type: wheeltype,
          url: wheelurl,
        }];

        game.game_ur_list.forEach((item, index) => {
          wheelspeed.splice(1, 0, {
            id: index + 2,
            type: 'img',
            url: item,
          });
        });

        wheelspeed.sort((a, b) => {
          return a.id > b.id ? 1 : -1;
        })

        setWheelseed(wheelspeed);

        setColorArray(game.bannerBgColors);

        aplus_queue.push({
          action: 'aplus.record',
          arguments: ['GameDetailViewAppear', 'CLK', {age: user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename: game.game_name, agent: agent}]
        });
      }
    })
    .catch(err => {
      Dialog.error(err.message);
    });
  }

  const load_game_user_info = ()=>{
    let data = {};
    if (props.query.id) {
      data.game_id = props.query.id;
    } else if (props.query.mid) {
      data.maiyou_gameid = props.query.mid;
    } else if(params.id){
      data.game_id = params.id;
    } else {
      Dialog.error('缺少参数', 1, () => history.goBack);
      return;
    }
    general.axios.post('/getGameUserInfo',data)
    .then(res=>{
      const {status,data} = res?.data
      if (!status?.succeed) {
        Dialog.error(status.error_desc);
      }else{
        setGameUserInfo(data)
        setShowTestDown(data.showTestDown);
      }
    })
  }

  useEffect(()=>{
    if (gameInfo && gameUserInfo){
      const game = {...gameInfo,...gameUserInfo}
      setGame(game)
      general.game = game;
    }
  },[gameInfo,gameUserInfo])

  const load = (parms = {}) => {
    if (loading) {
      return;
    }
    // selTestAgent();
    setLoading(true);
    Dialog.loading();
    let data = {};
    // console.log(props);
    // console.log(params);
    if (props.query.id) {
      data.game_id = props.query.id;
    } else if (props.query.mid) {
      data.maiyou_gameid = props.query.mid;
    } else if(params.id){
      data.game_id = params.id;
    } else {
      Dialog.error('缺少参数', 1, () => history.goBack);
      return;
    }

    if(props.query.id == '9457') {
      let url = "/";
      if(window.location.host == 'webbox.99maiyou.cn') {
        if(params.agent) {
          url = "https://webbox.99maiyou.cn/#/?agent=" + params.agent;
        } else {
          url = "https://webbox.99maiyou.cn/#/?agent=" + agent;
        }
      } else if(window.location.host == 'web.99maiyou.cn') {
        if(params.agent) {
          url = "https://web.99maiyou.cn/#/?agent=" + params.agent;
        } else {
          url = "https://web.99maiyou.cn/#/?agent=" + agent;
        }
      } else if(window.location.host == 'web.milu.com') {
        if(params.agent) {
          url = "https://web.milu.com/#/?agent=" + params.agent;
        } else {
          url = "https://web.milu.com/#/?agent=" + agent;
        }
      }
      
      window.location.href=url;

      return;
    }

    if(props.query.t) {
      data.t = props.query.t;
    } else if(params.t) {
      data.t = params.t;
    } 

    if(props.query.tt) {
      data.tt = props.query.tt;
    }

    if(!data.game_id) {
      if(!data.maiyou_gameid) {
        Dialog.error('缺少参数', 1, () => history.goBack);
        return;
      }
    }

    general.axios.post('/base/game/getGameDetail', data)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          if(status.error_desc == '游戏已下架') {
            let url = "/";
            if(window.location.host == 'webbox.99maiyou.cn') {
              if(params.agent) {
                url = "https://webbox.99maiyou.cn/#/?agent=" + params.agent;
              } else {
                url = "https://webbox.99maiyou.cn/#/?agent=" + agent;
              }
            } else if(window.location.host == 'web.99maiyou.cn') {
              if(params.agent) {
                url = "https://web.99maiyou.cn/#/?agent=" + params.agent;
              } else {
                url = "https://web.99maiyou.cn/#/?agent=" + agent;
              }
            } else if(window.location.host == 'web.milu.com') {
              if(params.agent) {
                url = "https://web.milu.com/#/?agent=" + params.agent;
              } else {
                url = "https://web.milu.com/#/?agent=" + agent;
              }
            }

            window.location.href=url;
          }
        } else {
          // let parAgent = "rr2f";
          // if(params.agent) {
          //   parAgent = params.agent;
          // }
          
          // let url = "https://webbox.99maiyou.cn/#/game?id="+data.game_info.game_id+"&agent=" + parAgent;
          // setGameUrl(url)
          // console.log(data.top_lable);
          setTopLable(data.top_lable);
          setBottomLable(data.bottom_lable);
          setShowTestDown(data.showTestDown);


          let game = {...data, ...data.game_info};
          setGame(game);
          general.game = game;
          let anyIosDownUrl = game.game_url.ios_url.replace('http://','https://');
          setGetIosUrl(anyIosDownUrl);

          let payUrl = game.supremePayUrl + "&from=react";
          setPayUrl(payUrl);

          setInit(true);

          // if(game.game_species_type == '2' || game.game_species_type == '3') {
          //   setRouterIndex(0);
          // }

          //搜索历史-202207新加
          if(params.source === 'searchHistory') {
            // let history_cover = searchHistory ? searchHistory ? [];
            let history_cover = searchHistory ? searchHistory : [];

            if(!searchHistory.includes(game.game_name)) {
              history_cover.push(game.game_name);
              setSearchHistory(history_cover);
            }
          }

          let wheeltype = '';
          let wheelurl = '';
          if(game.video_url) {
            wheeltype = 'video';
            wheelurl = game.video_url;
          } else {
            wheeltype = 'img-video';
            wheelurl = game.banner_url;
          }

          let wheelspeed = [{
            id: 1,
            type: wheeltype,
            url: wheelurl,
          }];

          game.game_ur_list.forEach((item, index) => {
            wheelspeed.splice(1, 0, {
              id: index + 2,
              type: 'img',
              url: item,
            });
          });

          wheelspeed.sort((a, b) => {
            return a.id > b.id ? 1 : -1;
          })

          setWheelseed(wheelspeed);

          setColorArray(game.bannerBgColors);

          aplus_queue.push({
            action: 'aplus.record',
            arguments: ['GameDetailViewAppear', 'CLK', {age: user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename: game.game_name, agent: agent}]
          });
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  useEffect(() => {
    if (props.active && localStorage.getItem('token') && !localStorage.getItem('yx_token')) {
      load_load()
    }
    if (props.active && !init) {
      // load();
      load_game_info()
      load_game_user_info()
    }
  }, [props.active]);

  // console.log(agent);
  const scroll = useScroll();
  const {run: setColour} = useThrottleFn(
    value => {
      props.setColour(value);
    },
    {wait: 10},
  );

  const [opacity, setOpacity] = useState(0);
  // const {run: setOpacity} = useThrottleFn(value => props.setOpacity(value), {wait: 10});
  useEffect(() => {
    let opacity;
    if (props.active) {
      if (scroll.top) {
        opacity = Math.abs(scroll.top) / 200;
        if (opacity > 1) {
          opacity = 1;
        }
      } else {
        opacity = 0;
      }
    }

    setOpacity(opacity);
  }, [scroll, props.active]);


  useUpdateEffect(() => {
    //2021-12-06 为测试视频暂时注释
    // if (props.active && init) {
    //   if (scroll.top) {
    //     let opacity = Math.abs(scroll.top) / 40;
    //     if (opacity > 1) {
    //       opacity = 1;
    //     }
    //     setColour('rgba(255,155,36, ' + opacity + ')');
    //     props.setTitle(<span style={{opacity: opacity}}>{game.game_name}</span>);
    //   } else {
    //     setColour('transparent');
    //     props.setTitle(<span style={{opacity: 0}}>{game.game_name}</span>);
    //   }
    // }
    if (props.active) {
      if (scroll.top < -80) {
        load_game_info()
        load_game_user_info()
      }

      //滑动暂停,不好使
      // if(scroll.top > 200) {
      //   video.play = false;
      //   video.eventPause = true;
      // }
    }
  }, [scroll, props.active]);

  //2021-12-06 为测试视频暂时增加 start
  // useEffect(() => {
  //   if(game) {
  //     props.setTitle(game.game_name);
  //   }
  // }, [game]);
  //2021-12-06 为测试视频暂时增加 end

  const [player, setPlayer] = useState();
  const [muted, setMuted] = useLocalStorageState('game-video-muted', true);
  const video = useReactive({});
  const [videoPlayState, setVideoPlayState] = useState();
  const onPlaying = () => {
    video.play = true;
    video.eventPause = false;
    video.activePause = false;
  }
  const onPause = () => {
    video.play = false;
    video.eventPause = true;
  }
  useEffect(() => {
    if (player) {
      if (props.active && video.activePause) {
        if(agent != "1eaz" && agent != '7dgd' && agent != 's7y0') {
          player.play();
        }
      }
      if (!props.active) {
        if (video.play) {
          player.pause();
          video.play = true;
        } else {
          player.pause();
        }
        if (video.play) {
          video.activePause = true;
        }
      }
    }
  }, [props.active, player]);
  const [routerIndex, setRouterIndex] = useState(props.query.page ? parseInt(props.query.page) : 0);
  const [swiper, setSwiper] = useState(null);
  useEffect(() => {
    if (swiper) {
      swiper.update();
    }
  }, [props.active, swiper]);
  useEffect(() => {
    if (swiper) {
      swiper.slideTo(routerIndex);
    }
  }, [routerIndex, swiper]);

  useEffect(() => {
    // props.setTitle(<div className="">
    //   <div className={'game-nav-head clearfix ' + (navFixed ? 'fixed' : '')} ref={navRef}>
    //     {router?.map((item, index) => {
    //       let count;
    //       if (item.id == 'gift') {

    //       } else if (item.id == 'comment') {
    //         count = game?.game_comment_num > 999 ? '999+' : game?.game_comment_num;
    //       } else if (item.id == 'trade') {

    //       }
    //       return <div
    //         className={'game-nav-item game-nav-' + item.id + ' ' + (routerIndex == index ? 'active' : '')}
    //         onClick={() => setRouterIndex(index)}
    //       >
    //         {item.name}
            
    //         {count < 100 ? null : 
    //         <div
    //           className="count"
    //         >
    //           {count}
    //         </div>
    //         }
    //       </div>
    //     })}
    //   </div>
    // </div>)
  }, [router, routerIndex]);

  const ref = useRef(null);
  const size = useSize(ref);
  const [navFixed, setNavFixed] = useState(0);
  const navRef = useRef();
  const navSize = useSize(navRef);
  const navPlaceRef = useRef();
  useUpdateEffect(() => {
    if (init) {
      //滑动暂停-播放
      // if(scroll.top > $(navPlaceRef.current).offset().top) {
      //   player.pause();
      // } else {
      //   player.play();
      // }
      if(routerIndex == 0) {
        setNavFixed(scroll.top + navSize.height > $(navPlaceRef.current).offset().top);
      }
      // setNavFixed(scroll.top > $(navPlaceRef.current).offset().top);
    }
  }, [scroll.top, size]);

  const [gift, setGift] = useState([]);
  const [giftShow, setGiftShow] = useState();
  const [giftCode, setGiftCode] = useState();
  const giftReceive = gift => {
    Dialog.loading();
    general.axios.post('https://api3.app.99maiyou.com/queryGameAccount', {maiyou_gameid: game.maiyou_gameid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        if(data.isExists) {
          Dialog.loading();
          general.axios.post('/base/game/getReceiveGift', {gift_id: gift.gift_id})
            .finally(() => {
              Dialog.close();
            })
            .then(res => {
              let {status, data} = res.data;
              // if(user.mobile){
                
              // } else {
              //   Dialog.error("未绑定手机号")
              // }
              if (!status.succeed) {
                Dialog.error(status.error_desc);
              } else {
                setGiftShow(false);
                setGiftCode(data);
                load_game_info()
                load_game_user_info()
              }
            })
            .catch(err => {
              Dialog.error(err.message);
            });
        } else {
          // setGiftShow(false);
          setTrumpetState(true);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });

    // Dialog.loading();
    // general.axios.post('/base/game/getReceiveGift', {gift_id: gift.gift_id})
    //   .finally(() => {
    //     Dialog.close();
    //   })
    //   .then(res => {
    //     let {status, data} = res.data;
    //     // if(user.mobile){
          
    //     // } else {
    //     //   Dialog.error("未绑定手机号")
    //     // }
    //     if (!status.succeed) {
    //       Dialog.error(status.error_desc);
    //     } else {
    //       setGiftShow(false);
    //       setGiftCode(data);
    //     }
    //   })
    //   .catch(err => {
    //     Dialog.error(err.message);
    //   });
  };

  // const [voucher, setVoucher] = useState(!!props.query.voucher || false);
  const [voucher, setVoucher] = useState(false);

  const favorite = () => {
    setGame({...game, is_collected: !game.is_collected});
    Dialog.loading();
    general.axios.post(game.is_collected ? '/cancelCollectGame' : '/collectGame', {id: game.maiyou_gameid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let result = res.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          let message = game.is_collected ? '取消成功' : '收藏成功'
          Dialog.success(message);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }
  // console.log(navigator.userAgent);
  
  // console.log(getIosUrl);
  // console.log(getIosUrl.replace('http','https'));
  const [downTipsState, setDownTipsState] = useState(false);
  const download = useLockFn(async () => {
    Manager.push({event_id:'D-2-20'})
    //判断是否在微信浏览器打开
    if(mobileDetect.userAgent() == 'WeChat' || mobileDetect.userAgents() == '微信' || navigator.userAgent.match(/MicroMessenger|QQ[^Browser]+/i)){
      setWeChatImg(true);
    } else {
      if (mobileDetect.is('iPhone') || mobileDetect.is('iPad')) {
        Dialog.loading();
        // var strIosUrl = getIosUrl.replace('http://', 'https://');
        // await general.axios.post(getIosUrl, '', {timeout: 600000})
        setDownTipsState(true);

        await generalnew.axiosnew.get(getIosUrl)
          .finally(() => {
            // Dialog.close();
            Dialog.close();
          })
          .then(res => {
            let {status, data} = res.data;
            if (!status.succeed) {
              Dialog.error(status.error_desc);
            } else {
              if(mb == 1 || (agent == '1eaz' || agent == '0zme')) {
                let hrefUrl = URI('app://browser/')
                        .addQuery('url', URI(data.url)
                        )
                        .toString();
                
                window.location.href = hrefUrl;
              } else {
                window.location.href = data.url;
              }
            }
          })
          .catch(err => {
            Dialog.error(err.message);
          });
      } else {
        //临时测试记录--渠道号不一致
        if(agent == 'rr2f') {
          // temporaryAdjuct();
        }

        if (navigator.userAgent.match(/MicroMessenger|QQ[^Browser]+/i)) {
          setWeChatImg(true);
        } else {
          let jumpUrl  = ''
          if(agent == 'ro46'){
            // jumpUrl = '/jump/';
            window.location.href = "http://app.milu.com/?a=" + agent;
          } else {
            if(document.referrer.match(/tieba+/i)) {
              window.location.href = game.game_url.android_url + '/bdvid/tieba';
            } else {
              window.location.href = game.game_url.android_url;
            }
          }
        }
      }
    }
  });

  //临时调试方法
  const temporaryAdjuct = ()=>{
    let data = {};
    data.agent = agent;
    data.host = window.location.href;
    data.username = localStorage.getItem("username");
    data.type = 'download';
    general.axios.post('/home/testGame/temporaryAdjuct', data)
        .finally(() => {
          setRegistering(false);
          Dialog.close();
        })
        .then(response => {
       
        })
        .catch(err => {

        });
  }

  const [vipDownload, setVipDownload] = useState(false);
  const [vipDownloadSafari, setVipDownloadSafari] = useState(false);
  // const [copyBoxImg, setCopyBoxImg] = useState(true);

  const reserve = () => {
    if (!user.mobile){
      history.push('/user/phone');
      return
    }
    setGame({...game, is_reserved: !game.is_reserved});
    general.axios.post(game.is_reserved ? '/cancelReserveGame' : '/reserveGame', {id: game.maiyou_gameid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let result = res.data;
        if (!result.status.succeed) {
          Dialog.error(result.status.error_desc);
        } else {
          let message = game.is_reserved ? '取消成功' : '预约成功';
          Dialog.success(message);
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  };

  const [isCheckAdult, setIsCheckAdult] = useState(false); //未成年温馨提示状态
  const [isCheckAuth, setIsCheckAuth] = useState(false); //实名认证弹框状态
  const [authData, setAuthData] = useState({}); //实名认证参数值
  
  const startPlay = () => {
    if(general.isLogin) {
      if(user.isCheckAuth && !user.isRealNameAuth) {
        setIsCheckAuth(true);
        return;
      }
      if(user.isCheckAdult && !user.isAdult) {
        setIsCheckAdult(true);
        return;
      }

      // let hrefsh5 = 'https://h5.sdk.wakaifu.com/game/autologin?game_id=' + game.maiyou_gameid + '&agent=' + agent + '&username=' + localStorage.getItem('username') + '&token=' + localStorage.getItem('token');
      // window.open(hrefsh5, '_blank');
      window.location.href = 'https://h5.sdk.wakaifu.com/game/autologin?game_id=' + game.maiyou_gameid + '&agent=' + agent + '&username=' + localStorage.getItem('username') + '&token=' + localStorage.getItem('token') + '&from=react&origin=react&source=react';
    }
  }
  //实名认证
  const certifiy = useLockFn(()=>{
    if(!authData.real_name) {
      Dialog.info('请输入姓名');
      return;
    }
    if(!authData.identity_card){
      Dialog.info('请输入身份证号');
      return;
    }
    Dialog.loading();
    general.axios.post('/user/user/editMemberInfo', {real_name:authData.real_name, identity_card:authData.identity_card})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          Dialog.info("认证成功");
          setIsCheckAuth(false);
          // setUser({...user, isRealNameAuth: true});
          setUser({...user, ...{real_name: authData.real_name, identity_card: authData.identity_card, isRealNameAuth: true, isAdult: data.isAdult}});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
    })


  const [paying, setPaying] = useState(false);
  // 
  // useEffect(() => {
  //   if (pay) {
  //     let url = "https://sys.wakaifu.com/home/mixPay/supreme?token=5c4a98ee055d01c6c5f8790540d165fc&username=18310369881&udid=00008101-00060CC91402001E";
  //     setPayUrl(url);
  //   } else {
  //     setPayUrl(null);
  //   }
  // }, [pay]);
  // Dialog.info(game.maiyou_gameid);
  // console.log(game.game_url.android_url);
  // console.log(payUrl);
  const query_down_pay = () => {
    setPaying(false);
    Dialog.dialog({
      type: 'modal',
      mask: true,
      title: '是否已支付？',
      btns: [
        {
          text: '否', onPress: () => {
          
          }
        },
        {
          text: '是', onPress: () => {
            Dialog.loading();
            general.axios.post('/queryOrder', {type: 'supreme'})
            .finally(() => {
              Dialog.close();
            })
            .then(res => {
              let {status,data} = res.data;
              // console.log(data);
              if(data.payStatus == 1) {
                Dialog.info('支付成功');
                setGame({...game, isPayUser: true});
                navConfig();
              } else {
                Dialog.info('支付被取消');
              }
            })
            .catch(err => {
              Dialog.error('支付被取消');
            });
          }
        }
      ]
    });
  }

  const navConfig = () => {
    general.axios.get('/base/common/getConfig')
      .then(res => {
        let {status, data} = res.data;
        if (status.succeed) {
          setConfig({...config, ...data});
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  useEffect(()=>{
    if(paying) {
      setTimeout(()=>{query_down_pay()}, 13000);
      // query_down_pay();
    }
  }, [paying])

  const [inviteexchangeState, setInviteexchangeState] = useState(false);

  useEffect(()=>{
    if(inviteexchangeState) {
      load_game_info()
      load_game_user_info()
      setInviteexchangeState(false);
    }
  }, [inviteexchangeState])


  const [giftDescInfoImage, setGiftDescInfoImage] = useState();

  const giftShowNewWindow = item => {
    Dialog.loading();

    general.axios.post('/base/gift/getGiftDetail', {gift_id: item.packid})
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        let descData = data.gift_info;
        setGift(data.gift_info);
        setGiftDescInfoImage(descData.new_image.thumb);
        setGiftShow(true);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  }

  const [proActiveState, setProActiveState] = useState(true);

  useEffect(()=>{
    setTimeout(()=>{
      projectSourceDia();
    }, 500)
  }, [params.source, init]);

  const projectSourceDia = () => {
    if(params.source && params.source === 'project' && proActiveState && init) {
      setProActiveState(false);
      Dialog.dialog({
        type: 'modal',
        mask: true,
        title: '温馨提示',
        content: '是否前往查看活动详情？',
        btns: [
          {
            text: '是', onPress: () => {
              history.push("/article/detail?id="+ params.newid);
            }
          },
          {
            text: '否', onPress: () => {
            
            }
          },
        ]
      });
    }
  }

  useEffect(()=>{
    if(agent != "1eaz" && agent != '7dgd' && agent != 's7y0') {
      setVideoPlayState(true);
    }
  }, [agent])

  useEffect(()=>{
    if(params.isExists && params.isExists == 1 && init) {
      if(game.game_species_type == 3) {
        startPlay();
      } else {
        if(mobileDetect.is('iPhone') || mobileDetect.is('iPad')) {
          // setVipDownload(true);
          upgrade();
        } else {
          download();
        }
      }
    }
  }, [params.isExists, init])


  useEffect(()=>{
    if(params.userid && params.username && params.token && params.udid && init) {
      if(mobileDetect.is('iPhone') || mobileDetect.is('iPad')) {
        // setVipDownload(true);
        upgrade();
      }
    }
  }, [params.udid, params.userid, params.username, params.token, init])
  // useEffect(() => {
  //   if(game_species_type == '2' || game_species_type == '3') {
  //     setRouterIndex(0);
  //   }
  // }, [game.game_species_type]);
// console.log(bottomLable);
  // console.log(mobileDetect.userAgents());


  const [waitRoamState, setWaitRoamState] = useState(false);//等待排队弹框
  const [startRoamState, setStartRoamState] = useState(false);//开始云玩弹框
  // const [progressNum, setProgressNum] = useState(0);//进度条数值
  // const [testProgress, setTestProgress] = useState(true);

  //判断有没有云主机
  const isroam = useLockFn(() => {
    //实名认证弹框
    if(user.isCheckAuth && !user.isRealNameAuth) {
      setIsCheckAuth(true);
      return;
    }
    if(user.isCheckAdult && !user.isAdult) {
      setIsCheckAdult(true);
      return;
    }

    clickToCloudGames();
    // console.log(config.cloudappIndexUrl);
    // window.location.href=config.cloudappIndexUrl+"?gameid=" + game.maiyou_gameid + "&username=" + localStorage.getItem('username') + "&token=" + localStorage.getItem('token') + "&userid="+localStorage.getItem('user_id');
    Dialog.loading();
    general.axios.get(game.cloudappCheckAvailableUrl + "?gameid=" + game.maiyou_gameid + "&username=" + localStorage.getItem('username'))
      .finally(() => {
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.info(status.error_desc);
        } else {
          if(data.isValid == '1') {
            setStartRoamState(true);
          } else {
            setWaitRoamState(true);
          }
        }
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  })

  const startRoamPlay = () => {
    setStartRoamState(false);
    window.location.href=config.cloudappIndexUrl+"?gameid=" + game.maiyou_gameid + "&username=" + localStorage.getItem('username') + "&token=" + localStorage.getItem('token') + "&userid="+localStorage.getItem('user_id') + "&origin=react&location_url=" + encodeURIComponent(window.location.origin + '/#/game?id='+params.id);
  }

  // const [interval] = useState(1000);
  // const [testTime, setTestTime] = useState(false);
  // const [gameExitConfirm, setGameExitConfirm] = useState(false);
  
  // useInterval(
  //   () => {
  //     if(!testTime) {
  //       // if(progressNum < 90) {
  //       //   setProgressNum(progressNum > 90 ? '90': progressNum + 1);
  //       // }
  //       let num = 0;
  //       if(progressNum < 90) {
  //         let size = game?.game_size.ios_size.split(" ");
  //         if(size != undefined) {
  //           if(size[1] == 'GB') {
  //             num = 90 / (size[0] * 1024 / 6 * 2) + 0.01;
  //           } else {
  //             num = 90 / (size[0] / 3 * 2) + 0.01;
  //           }
  //         }
  //       }
        
  //       setProgressNum(Math.floor((progressNum + num) * 100) /100);
  //     } else {
  //       setProgressNum(100);
  //     }
  //   },
  //   interval,
  //   {immediate: true},
  // );

  //这是监听云游支付地址信息
  useEffect(()=>{
    window.addEventListener('message', function(event) {
      var result = event.data;
      // if (result.success){
      //     window.location.href = result.pay_url;
      // }
      if(result?.type) {
        if(result.type == 'pay') {
          window.location.href = result.pay_url;
        } else if(result.type == 'kefu') {
          // history.push("/service");
          window.location.href = window.location.origin + "/#/service";
        }
      } else {
        if (result?.pay_url){
          window.location.href = result.pay_url;
        }
      }
    });
  });

  //ios16处理
  const [upgradeState, setUpgradeState] = useState(false);
  const upgrade = useLockFn(() => {
    // let version = mobileDetect.versionStr('iPhone');

    if(config?.is_not_login == false && (localStorage.getItem('username') == '' || localStorage.getItem('username') == null)) {
      Dialog.info('用户名为空,请重新登录重试~');
      return;
    }

    let version;
    if(mobileDetect.is('iPad')) {
      version = mobileDetect.versionStr('iPad');
    } else if(mobileDetect.is('iPhone')) {
      version = mobileDetect.versionStr('iPhone');
    }
    
    if(version.split("_").includes("16") == true) {
      if(udid || params.udid || game.udid) {
        setUpgradeState(true);
      } else {
        setVipDownload(true);
      }
    } else {
      setVipDownload(true);
    }

    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickToDownloadTheGame', 'CLK', {age:user?.age, level : user?.vip_level, sex : user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename: game?.game_name, agent: agent}]
    });
  })

  //极速云玩点击埋点
  const clickToCloudGames = () => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickToCloudGames', 'CLK', {age:user?.age, level : user?.vip_level, sex : user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), gamename: game?.game_name, agent: agent}]
    });
  }

  // console.log(moment().unix());

  //添加至桌面
  // const isDeskCloudApp = () => {
  //   let hrefs = "https://webbox.mlaps0705.com/#/?gameid=" + game.maiyou_gameid +"&gamename="+game.game_name+"&icon=" + game.game_image?.thumb + "&username=" + localStorage.getItem('username') + "&token=" + localStorage.getItem('token') + "&agent=" + agent + "&time="+moment().unix() + "&bookmarkId=2904878F-67C5-5559-17C4-897EF81BB3AE";
  //   window.open(hrefs, "_blank")
  // }

  const isDeskCloudApp = () => {
    //https://webbox.mlaps0705.com
    //https://cloudgame.99maiyou.cn/#/
    // let hrefs = "https://cloudgame.99maiyou.cn/#/?gameid=" + game.maiyou_gameid +"&gamename="+game.game_name+"&icon=" + game.game_image?.thumb + "&username=" + localStorage.getItem('username') + "&token=" + localStorage.getItem('token') + "&agent=" + agent + "&time="+moment().unix() + "&bookmarkId=2904878F-67C5-5559-17C4-897EF81BB3AE";
    // window.open(hrefs, "_blank")
    //http://gamebox.sxswyc.top/

    if(mb == 1 || (agent == '1eaz' || agent == 'l61u' || agent == '9ahi' || agent == '0zme')) {
      let hrefs = "https://cloudgame.99maiyou.cn/#/?gameid=" + game.maiyou_gameid + "&icon=" + game.game_image?.thumb + "&username=" + localStorage.getItem('username') + "&token=" + localStorage.getItem('token') + "&agent=" + agent + "&time="+moment().unix() + "&udid="+udid + "&user_id=" + localStorage.getItem('user_id') + "&key="+key;

      let hrefUrl = URI('app://browser/')
              .addQuery('url', hrefs).toString();
        
      window.location.href = hrefUrl;
    } else {
      let hrefs = "https://cloudgame.99maiyou.cn/#/?gameid=" + game.maiyou_gameid +"&gamename="+game.game_name+"&icon=" + game.game_image?.thumb + "&username=" + localStorage.getItem('username') + "&token=" + localStorage.getItem('token') + "&agent=" + agent + "&time="+moment().unix() + "&udid="+udid+"&user_id="+localStorage.getItem('user_id') + "&key="+key;
      window.open(hrefs, "_blank")
    }
  }

  const customizeJump = () => {
    setCustomizeState(false);
    window.location.href = game?.jumpBtnInfo?.popJumpUrl;
  }

  // console.log(mobileDetect.userAgents());

  const backRef = useRef();
  const operateRef = useRef();
  const backSize = useSize(backRef);
  const operateSize = useSize(operateRef);

  useEffect(() => {
    if (operateSize.width > backSize.width) {
      if (backRef.current) {
        $(backRef.current).css('width', operateSize.width);
      }
    }
  }, [backSize, operateSize]);

  const [swiperIndex, setSwiperIndex] = useState(0);

  // console.log(swiperIndex);

  // const [colorArray, setColorArray] = useState(['#4B3269','#693C36','#863816','#A73E1B','#693C36','#452016','#3E110E','#27889C']);

  return game ? <div className="game-detail" ref={ref}>
    <div className="game-body" style={{display:game?'':'none'}}>
      <div className="game-view-head">
        <div className="background" style={{opacity: (routerIndex == 0 ? opacity : 1)}}/>
        <div className={"container " + (routerIndex == 0 && opacity == 0 ? ' white-container' : '')}>
          <div className="back" ref={backRef} onClick={() => {
            // general.history.goBack()
            if(params.indexsource) {
              let url = "/";
              if(window.location.host == 'webbox.99maiyou.cn') {
                if(params.agent) {
                  url = "https://webbox.99maiyou.cn/#/?agent=" + params.agent;
                } else {
                  url = "https://webbox.99maiyou.cn/#/?agent=" + agent;
                }
              } else if(window.location.host == 'web.99maiyou.cn') {
                if(params.agent) {
                  url = "https://web.99maiyou.cn/#/?agent=" + params.agent;
                } else {
                  url = "https://web.99maiyou.cn/#/?agent=" + agent;
                }
              } else if(window.location.host == 'web.milu.com') {
                if(params.agent) {
                  url = "https://web.milu.com/#/?agent=" + params.agent;
                } else {
                  url = "https://web.milu.com/#/?agent=" + agent;
                }
              }
              
              window.location.href=url;
            } else {
              general.history.goBack()
            }
            }}><LeftOutlined/></div>
          <div className="title">
            <div className={'game-nav-head clearfix ' + (navFixed ? 'fixed' : '')} ref={navRef}>
              {router?.map((item, index) => {
                let count = '';
                if (item.id == 'gift') {

                } else if (item.id == 'comment') {
                  if (game?.game_comment_num > 999){
                    count = '999+'
                  }else{
                    count = game?.game_comment_num > 99 ? game?.game_comment_num : '';
                  }
                } else if (item.id == 'tools') {
                  count = game?.kitsCount > 0 ? game?.kitsCount.toString() : '';
                }
                return <div
                  className={'game-nav-head-item game-nav-' + item.id + ' ' + (routerIndex == index ? 'active' : '') + ' game-nav-width' + router.length}
                  onClick={() => setRouterIndex(index)}
                >
                  {item.name}
                  
                  {count.length == 0 ? null : 
                  <div
                    style={{
                      color:(routerIndex == 0 ? (opacity == 0 ? 'white' : '#999') : '#999'),
                      left: '50%',
                      transform: 'translateX(50%)',
                      width: '30px',
                      paddingLeft:'0px'
                      // right:parseInt(count) < 10 ? '6px' : (parseInt(game?.game_comment_num) > 999 ? '-7%' : '2px')
                    }}
                    className={"count-head count-head-" + router.length}
                  >
                    {count}
                  </div>
                  }
                </div>
              })}
            </div>
          </div>
          <div className="operate">
            {(()=>{
              let url = window.location.href;
              let underUrl = window.location.href;
              if(window.location.host == 'webbox.99maiyou.cn' || window.location.host == 'web.99maiyou.cn' || window.location.host == 'web.milu.com') {
                if(params.agent) {
                  url = window.location.href
                } else if(props.query.agent) {
                  url = window.location.href
                } else {
                  url = window.location.href + "&agent=" + agent;
                  underUrl = window.location.href + "&agent=" + agent;
                }
              }
          
              {/* return <CopyToClipboard text={url} onCopy={() => Dialog.info('已复制链接')}><img src={"/resources/game/"+((routerIndex == 0 && opacity == 0 ? 'white-share' : 'share'))+".png"} className="share-img"/></CopyToClipboard> */}
              return <div className="share-img"></div>
            })()}
          </div>
        </div>
      </div>

      {routerIndex == 0 ? <>
      <div className="game-index-detial-swiper">
        {/* <div className="index-top-bg" style={{backgroundColor:'rgb(19, 26, 58)'}}/> */}

        <div className="index-top-bg" style={{backgroundColor:' ' + colorArray[swiperIndex] + ' '}}/>
        {/* <div className="index-top-bg" style={{backgroundImage:'url('+game?.game_ur_list[swiperIndex]+')'}}/> */}
        <div className="game-video" style={{backgroundColor:' '+ colorArray[swiperIndex] +' '}}>
          {/* {game.video_url ?
            <Player autoPlay={videoPlayState} playsInline={true} muted={muted} onVolumeChange={e => setMuted(e.target.muted)}
                    fluid={false} width="100%" height="100%" ref={player => setPlayer(player)} onPlaying={onPlaying}
                    onPause={onPause} webkit-playsinline="true" playsinline="true">
              <source src={game.video_url}/>
            </Player> : null}
            {!videoPlayState ? !video.play ? <div className="playfb" onClick={()=>{player.play()}}><PlayCircleOutlined/></div> : null : null} */}

          {/* {game.video_url ?
            <video autoPlay={videoPlayState} playsInline={true} src={game.video_url} controls="controls" webkit-playsinline="true" playsinline="true" muted={muted} onVolumeChange={e => setMuted(e.target.muted)}
            fluid="true" width="100%" height="100%" ref={player => setPlayer(player)} onPlay={onPlaying} onPause={onPause}
            x-webkit-airplay="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-ignore-metadata="true" />
          : <img src={game.banner_url}/>}
          {!videoPlayState ? !video.play ? <div className="playfb" onClick={()=>{player.play()}}><PlayCircleOutlined/></div> : null : null} */}

          <Swiper loop={false}
            initialSlide={0} //定位从第几个slider开始轮播
            watchSlidesProgress={true}
            // spaceBetween={'30'}
            // freeMode={true}
            // freeModeMomentumBounce={false}
            slidesPerView={'auto'}
            // centeredSlides={true}
            onSlideChange={swiper => {
              setSwiperIndex(swiper.realIndex);
            }}
            loopedSlides={1}
            observer={true}
            observeParents={true}
            pagination={{clickable: true}}
            >
            {wheelseed?.map((item, index, imgs) => <SwiperSlide className={item.type}>
                {/* <div className="index-befor-Uh1BX-top" style={{backgroundColor:'rgb(19, 26, 58)'}}></div> */}
                <div className="index-befor-Uh1BX" style={{backgroundImage: 'linear-gradient('+colorArray[swiperIndex]+' 30%, rgba(6, 36, 41, 0) 100%)'}}></div>
                {/* <div className="index-after-KUKvi" style={{backgroundImage: 'linear-gradient(-180deg, rgba(10, 28, 29, 0) 0%, '+colorArray[swiperIndex]+' 100%)'}}></div> */}
                <div className="index-after-KUKvi" style={{backgroundImage: 'linear-gradient(-180deg, rgba(10, 28, 29, 0) 0%, '+colorArray[swiperIndex]+' 100%)'}}></div>
                <div className="index-after-KUKvi-bot" style={{backgroundColor:' ' + colorArray[swiperIndex] +' '}}/>
                {
                  item.type == 'video' ? 
                  <>
                    <video autoPlay={videoPlayState} playsInline={true} controls="controls" webkit-playsinline="true" playsinline="true" muted={muted} onVolumeChange={e => setMuted(e.target.muted)}
                    fluid="true" width="100%" height="100%" ref={player => setPlayer(player)} onPlay={onPlaying} onPause={onPause}
                    x-webkit-airplay="true" x5-video-player-type="h5" x5-video-player-fullscreen="true" x5-video-ignore-metadata="true">
                       <source src={item.url}/>
                    </video>
                    {!videoPlayState ? !video.play ? <div className="playfb" onClick={()=>{player.play()}}><PlayCircleOutlined/></div> : null : null}
                  </> :
                  item.type =='img-video' ? <>
                  {(() => {
                    let imgstring = '';
                    imgs.forEach((item) => {
                      if(item.type=='img' || item.type == 'img-video') {
                        imgstring += item.url + ',';
                      }
                    });

                    let basic = imgstring.substring(0, imgstring.lastIndexOf(','));

                    return <div className="swiper-imgs" >
                      {/* <div className="aaa"></div> */}
                      {/* <div className="all" style={{backgroundImage:"url("+item.url+")"}}><img src={item.url}/></div> */}
                      <Link to={'/img?imgs='+basic+'&img=' + (index-1)}><img src={item.url}/></Link>
                      {/* <div className="bbbb"></div> */}
                    </div>
                  })()}
                  </>
                  :
                  <>
                  {(() => {
                    let imgstring = '';
                    imgs.forEach((item) => {
                      if(item.type=='img' || item.type == 'img-video') {
                        imgstring += item.url + ',';
                      }
                    });

                    let basic = imgstring.substring(0, imgstring.lastIndexOf(','));

                    return <div className="swiper-imgs">
                      {/* <div className="aaa"></div> */}
                      {/* <div className="all" style={{backgroundImage:"url("+item.url+")"}}><img src={item.url}/></div> */}
                      <Link to={'/img?imgs='+basic+'&img=' + (index-1)}><img src={item.url} /></Link>
                      {/* <div className="bbbb"></div> */}
                    </div>
                  })()}
                  {/* <div className="swiper-imgs"><Link to={'/img?imgs='+imgs.url+'&img=' + index}><img src={item.url}/></Link><img src={item.url}/></div> */}
                  </>
                }
            </SwiperSlide>)}
          </Swiper>
        </div>
        {/* <div className="index-btm-bg" style={{backgroundColor:'rgb(19, 26, 58)'}}/> */}
        <div className="index-btm-bg" style={{backgroundColor:' ' + colorArray[swiperIndex] + ' '}}/>
      </div>
      <div className="game-info">
        <div className="icon">
          <Image src={game.game_image?.thumb}/>
          {/* {game.game_species_type == 1 ? 
          <div className="type_label">BT</div>
          : null} */}
          {/* <div className="type_label">
            {game.game_species_type == 1 ? 'BT' : game.game_species_type == 3 ? 'H5' : game.game_species_type == 2 && game.discount == 1 ? '官方' : ''}
          </div> */}
          {
            game?.lablePosition == 2 && game.top_lable != '' ?
            <div className="iconlable jx" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
              {game.top_lable}
            </div>
            : null
          }
        </div>
        <div className="info">
          {localStorage.getItem('username') == "root" || ceshiNumbei.includes(user?.member_name) ?
          <div style={{
            position:'absolute',top:'0',right:'10px',color:'blue'
          }} onClick={()=>{
            // history.push('/worryfree?id='+game.maiyou_gameid)
            window.location.href = "apple-magnifier://install?url=" + game.game_url.ios_parent_url
          }}>TrollStore</div> : null}
          <div className="name">
            {/* {topLable != '' ? <div className="span">{topLable}</div> : null }{game.game_name} */}
            <div className={" " + (game.nameRemark ? " name-new" : "")}>
              {game?.lablePosition == 1 && game.top_lable != '' ? <span className="span">{game.top_lable }</span> : null }
              {game.game_name}
            </div>
          </div>
          <div className="info1">
            {game.allow_download == 0 && game.isOpenCloudApp ? '' :
            <>{game.game_species_type != '3' ? mobileDetect.os() == 'iOS' ? game.game_size?.ios_size + ' ' : game.game_size.android_size + ' ': null}</>
            }
            <Subtitle game={game}>{game.nameRemark}</Subtitle>
          </div>
          
            <div className="info3">
              {
                bottomLable && game.bottom_lable ? 
                <>
                {Object.keys(bottomLable).length > 0 ? 
                <div >
                  <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{bottomLable.left_content}</span><span className="second-text" style={{color:bottomLable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:bottomLable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{bottomLable.right_content}</span>
                </div> : null }
                </>
                : null
              }
              {Object.keys(game.bottom_lable).length == 0 && game.introduction ? <span style={{color:'#888'}}>{game.introduction}</span> : null}              </div>
        </div>
        {game.goodGame?.length > 0 ? 
        <div className="goodGame">
          <img className="goodgame-icon" src="resources/index/left.png" alt="" />
          <div className="goodgame-content">
            <div className="goodgame-title">
              <div className="goodgame-name">{game.discount * 10}</div>
              <div className="goodgame-desc">折</div>
            </div>
            <div className="goodgame-text">{game.goodGame}</div>
          </div>
          <img className="goodgame-icon" src="resources/index/right.png" alt="" />
        </div> : null}
        {/* {(mobileDetect.is('iPhone') || mobileDetect.is('iPad')) && game.isOpenCloudApp ? 
        <NavLink className="roam" onClick={()=>isroam()}><img src="/resources/game/roam.png" /></NavLink>
        :null} */}
        {/* {game.discount < 1 ? <div className="discount" style={{backgroundImage:'url(/resources/game/new-discount-text-bg.png)'}}><div className="number">{game.discount * 10}折</div></div> : null} */}
        {
          (game.game_species_type != 1 && game.goodGame.length == 0) || (game.game_species_type == 1 && game.discount < 1 && game.discount > 0 && game.goodGame.length == 0)  ?
          <div className="discount" >
            <div className="number">
              {(()=>{
                if(game.discount < 1 ) {
                  return <span>{(game.discount * 10)}<span style={{fontSize:'11px'}}>折</span></span>
                }
              })()}
            </div>
          </div>
          : null
        }
      </div>

      <div className="game-hand-tags">
      {game?.game_tally.map((item,index)=>{

        if(item.type == 'balancePay' || item.type == 'interwork') {
          return <div 
            onClick={()=>{
              Manager.push({event_id:item.event_id,data:{id:item.id}})
              if(item.type == 'interwork' ) {
                if(item.value) {
                  Dialog.alert('温馨提示', '该游戏支持安卓端和苹果端游戏数据共享和角色互通')
                } else {
                  Dialog.alert('温馨提示', '该游戏不支持安卓端和苹果端游戏数据共享和角色互通')
                }
              } else if(item.type == 'balancePay' ) {
                if(item.value) {
                  Dialog.alert('温馨提示', '该游戏内可以使用金币支付')
                } else {
                  Dialog.alert('温馨提示', '该游戏内不可使用金币支付')
                }
              }
            }}
            key={index} 
            className={"tag " + (item?.value ? '' : ' nonsupport')}>
          <span >{item.tagname}</span>
          {/* <RightOutlined style={{fontSize:'8px',color:"#999"}}></RightOutlined> */}
        </div>
        } else {
          return <NavLink onClick={()=>Manager.push({event_id:item.event_id,data:{id:item.id}})} user={false} to={`/extendgame?id=${item.id}&type=${item.type}`} key={index} className={"tag"}>
            <span >{item.tagname}</span>
            <RightOutlined style={{fontSize:'8px',color:"#999"}}></RightOutlined>
          </NavLink>
        }

        // return <NavLink onClick={()=>Manager.push({event_id:item.event_id,data:{id:item.id}})} user={false} to={`/extendgame?id=${item.id}&type=${item.type}`} key={index} className={"tag"}>
        //   <span >{item.tagname}</span>
        //   <RightOutlined style={{fontSize:'8px',color:"#999"}}></RightOutlined>
        // </NavLink>
      })}
      </div>

      <div className="game-menu-new">

      <div onClick={() => {
          Manager.push({event_id:'D-2-6'})
          if(game.voucher_info.amount > 0) {
            history.push("/detail/coupon?id=" + game.game_id);
          } else {
            if (game.voucherDesc?.length > 0){
              history.push("/save/newcard");
            }else{
              Dialog.info('暂无可领取代金券');
            }
          }
        }} className="item voucher-item">
          <div className="head">
            <div className={game.voucher_info?.amount > 0 ? "badge" : (game.voucherDesc?.length > 0 ? 'card-badge' : 'block-badge')} style={{display:game.voucherDesc.length === 0 ? '' : 'none'}}>{'￥' + parseInt(game.voucher_info?.amount)}</div>
            {/* {game.voucher_info.num > 0 ? <div className="badge">{game.voucher_info.num}</div> : ''} */}
          </div>
          <div className="body" 
               style={{
                fontSize:game.voucher_info?.amount > 0 || game.voucherDesc.length === 0 ? '.22rem' : '.3rem',
                fontWeight:game.voucher_info?.amount > 0 || game.voucherDesc.length === 0 ? '400' : 'bold',
                color:game.voucher_info?.amount > 0 || game.voucherDesc.length === 0 ? '#666' : '#282828'
                }}>
            {game.voucher_info?.amount > 0 || game.voucherDesc.length === 0 ? game?.voucherTitle : game.voucherDesc}<span><img src="/resources/welfare/left-back.png" class="left-back" /></span>
          </div>
      </div>

      <div className="hr"></div>
      
      <div onClick={()=>{
          // history.push("/game/server?id=" + game.game_id);
          Manager.push({event_id:'D-2-7'})
          setServerShow(true)
        }} className="item server-item">
          {Object.keys(game?.openServerLast).length > 0 && game?.openServerLast?.kaifuname != null ?
          <>
          <div className="head">
            <div className="badge">{game.openServerLast?.starttime ? moment(game?.openServerLast?.starttime * 1000).format('HH:mm') : ''}</div>
          </div>
          <div className="body">
            {game?.openServerLast?.kaifuname}
            <span><img src="/resources/welfare/left-back.png" class="left-back" /></span>
          </div>
          </>
          : <div className="head-dt">
            动态开服<span><img src="/resources/welfare/left-back.png" class="left-back" /></span>
            </div>}
        </div>
        <div className="hr"></div>
        <div onClick={() => {
          Manager.push({event_id:'D-2-8'})
          history.push({
            pathname:'/game/article?id=' + game.game_id,
            state:{gamename:game.game_name}
          });
          // if(game.activityNum > 0) {
          //   history.push('/game/article?id=' + game.game_id);
          // } else {
          //   Dialog.info('暂无游戏活动');
          // }
        }} className="item article-item">
          <div className="head">
            <div className="badge">{game.activityNum}</div>
            {/* {game.activityNum > 0 ?
              <div className="badge">{game.activityNum}</div>
            : null} */}
          </div>
          <div className="body">
            {game?.rebateActivitieTitle}<span><img src="/resources/welfare/left-back.png" class="left-back" /></span>
          </div>
        </div>
        <div className="hr"></div>
        
        <div onClick={()=>{
          Manager.push({event_id:'D-2-9'})
          history.push("/detailTrade?id=" + game.game_id);
        }} className="item server-item">
          <div className="head">
            <div className="badge">{game.tradeCount}</div>
          </div>
          <div className="body">
            精选交易
            <span><img src="/resources/welfare/left-back.png" class="left-back" /></span>
          </div>
        </div>

      </div>
      {gameInfo.is_open_cluster ?
      <NavLink user={true} onClick={()=>{
        Manager.push({event_id:'D-2-10'})
        if (user.isCheckAuth && !user.isRealNameAuth){
          setIsCheckAuth(true)
          return
        }
        general.axios.post('/base/yunxin/joinTeams',{topic_id:game.maiyou_gameid})
        .then(res=>{
          const { data, status} = res.data
          if (status.succeed){
            const {tid} = data.info
            if (localStorage.getItem('yx_login')){
              general.history.push(`/chat?tid=${tid}`)
            }else{
              PubSub.publish('loginSubscribe',true)
              setTimeout(()=>{
                general.history.push(`/chat?tid=${tid}`)
              },2000)
            }
          }else{
            Dialog.error(status.error_desc)
          }
        })
      }}>
      <div className="game-im">
        <div className="message">
          <Antd_swiper 
            direction="vertical"
            loop
            autoplay
            autoplayInterval={3000}
            indicator={() => null}
            style={{ '--height': '21px' }}
            >
            {gameInfo?.groupHistoryRecord.map((item, index) => (
              <Antd_swiper.Item key={index}>
                <img src={item.icon} alt="" />
                <div className="content">{item.msg}</div>
              </Antd_swiper.Item>
            ))}
          </Antd_swiper>
          <span className="join">加入群聊</span>
          <RightOutlined style={{color:"#999999",fontSize:'10px'}}></RightOutlined>
        </div>
      </div>
      </NavLink> : null}
      <Wechat></Wechat>

      
      {game.exclusive_benefit.length > 0 && <div className="welfare-items" style={{backgroundColor:'#fff',borderRadius:'8px',margin:'15px 15px 0px',paddingBottom:'10px'}}>
        <div style={{padding:'15px 0 0 10px',fontSize:'.32rem',fontWeight:'bold',color:'#282828'}}>专属福利</div>
        {exclusive_benefit.map((item,index)=>{
          return <NavLink user={item.needlogin} to={(() => {
            if (item.type == 'friend_help') {
              let url = URI(item.url)
                .addQuery('title', '好游助力')
                .addQuery('location_url', window.location.origin)
                .addQuery('origin', 'react')
                .toString();
              return url;
            }else if (item.type == 'community_recruit'){
              let url = URI(item.url)
                .addQuery('title', '社群管理招募公告')
                .addQuery('location_url', window.location.origin)
                .addQuery('origin', 'react')
                .toString();
              return url;
            }
            })()}
            iframe={{title: item.web_title}} onClick={()=>{
            Manager.push({event_id:item.event_id})
            if (item.type == 'easy_play') {
              history.push(`worryfree?id=${game.maiyou_gameid}`);
            }else if (item.type == 'try_play'){
              history.push(`task/tryplay?topic_id=${game.maiyou_gameid}`);
            }else if (item.type == 'strategy'){
              history.push(`communityDetail?id=${item.id}&tagid=2`);
            }else if (item.type == 'activitys'){
              history.push(`article/detail?id=${item.id}&type=activity`);
            }else if (item.type == 'sign_gift'){
              history.push(`gamesign?id=${game.maiyou_gameid}`);
            }
          }} key={index} className="game-worryfree-content">
          <img src={item.icon} alt="" />
          <div className="game-worryfree-content-container" style={{padding:'10px 0',borderBottom:'0.5px solid #f5f6f8'}}>
            <div className="title">{item.title}</div>
            <div className="desc">{item.desc}</div>
          </div>
          <RightOutlined style={{color:"#999999",fontSize:'11px'}}></RightOutlined>
        </NavLink>
      })}
        {!open && <div className="open" onClick={()=>{
          setOpen(true)
          setexclusive_benefit(game.exclusive_benefit)
        }} style={{display:'flex',justifyContent:'center',alignItems:'center',paddingTop:'5px'}}>
          <div className="text" style={{fontSize:'11px',color:'#999',paddingRight:'5px'}}>更多活动</div>
          <img src="resources/game/sign/down.png" width={8} height={8} alt="" />
        </div>}
      </div>}

      {game?.isOpenComplaint ? 
      <div className="game-repost-display-img">
        <NavLink to={"/detail/complaint?id="+game.maiyou_gameid+"&name="+game.game_name+"&agent="+agent}>
          <img src={game.complaintBannerImage?game.complaintBannerImage:'/resources/game/report_ml.png'} alt=""/>
        </NavLink>
      </div>
      : null
      }

      <div className="game-nav-place" style={{height: navFixed ? navSize.height : 0}} ref={navPlaceRef}/>
        </>
      : null}
      
      <div className={"game-main " + (routerIndex == 0 ? ' game-main-index' : '')}>
        {router.map((item, index) => 
          <div className={'game-main-item game-main-' + item.id + ' ' + (routerIndex === index ? 'active' : '')} style={{display: routerIndex === index ? 'block' : 'none'}}>
            <item.component active={routerIndex === index && props.active} game={game ? game : {game: {game_id: props.query.id}}}
                            setGame={setGame} setGift={setGift} setGiftShow={setGiftShow} giftReceive={giftReceive}  giftShowNewWindow={giftShowNewWindow}
                            load={load} setShow={setVoucher}/>
          </div>)}
      </div>
      
    </div>

    <div className="game-operate width" style={{display:game?'':'none'}}>
      {game.new_down_type == 3 && mobileDetect.is('iPhone') ? 
        <>
        {downThreeState ?
        <div className="game-operate-tips-ad">
          <div className="left">
              <div className="txt">
                  <p>如普通下载安装到桌面无法打开，请使用至尊版下载！</p>
              </div>
          </div>
          <div className="close" onClick={() => setDownThreeState(false)} style={{backgroundImage: 'url(/resources/index/white-close.png)'}}></div>
        </div>
        : null}
        </>
      : null}
      <div className="container">
        {/* <NavLink className={'favorite ' + (game.is_collected ? 'active' : '')} onClick={favorite}>
          <StarOutlined className="icon favorite"/>
          <StarFilled className="icon favorited"/>
          收藏
        </NavLink> */}
        <NavLink user={false} className="favorite">
          <CopyToClipboard text={url} onCopy={() => Dialog.info('已复制链接')}>
            <img width={17} src="resources/game/share2.png" alt="" />
          </CopyToClipboard>
          分享
        </NavLink>

        {game?.jumpBtnInfo?.isShow ?
        <div className="btns openCloud">
          <NavLink className={"btn customize-btn "} user={false} onClick={() => setCustomizeState(true)}>
            <p>
              <h2>{game?.jumpBtnInfo?.btnTitle}</h2>
            </p>
          </NavLink>
        </div>
        :
        <div className="btns openCloud">
          {showTestDown == 1 ? 
          <>
            {game.game_species_type == 3
              ? 
              <NavLink onClick={startPlay} iframe={{title: game.game_name}} className="btn btn-yunstartPlay">开始</NavLink>
              // <a
              //   href={'https://h5.sdk.wakaifu.com/game/autologin?game_id=' + game.maiyou_gameid + '&agent=' + agent + '&username=' + user.member_name + '&token=' + localStorage.getItem('token')}
              //   iframe={{title: game.game_name}} className="btn">开始</a>
              : <>
                {mobileDetect.is('iPhone') || mobileDetect.is('iPad') ? <>
                  {(ceshiNumbei.includes(user?.member_name) || moment(game.starting_time * 1000).format('YYYYMMDD') <= moment().format("YYYYMMDD")) && game.isOpenCloudApp  ? 
                    <NavLink className={(game.allow_download == 1 ? "iosBox room " : "btn onlyOneRoom") + (game.new_down_type == 3 ? ' roomSize ': '') } onClick={()=>isroam()}>

                      {game.new_down_type == 3 ? <img src="/resources/game/ywnnq-three.png" />
                      : (game.allow_download == 1 ?
                        <img src="/resources/game/ywnnq.png" />
                        :null)
                      }
                      <p>
                        <img src="/resources/game/y.png" />
                        <h2>云玩</h2>
                      </p>
                      
                    </NavLink>
                  : null} 

                  {game.new_down_type == 3 ? 
                    <>
                    {navigator.userAgent.match(/MicroMessenger|QQ[^Browser]+/i) || mobileDetect.userAgents() == 'UCBrowser' ? 
                    <CopyToClipboard text={underUrl} onCopy={() => Dialog.info('已复制链接')}>
                      <NavLink user={config?.is_not_login == true ? false : true} className={game.isOpenCloudApp ? "iosBox middle" : "btn"} onClick={() => setVipDownloadSafari(true)}>
                        <img src="/resources/game/new-middle-d-n.png" />
                        <p><h2>下载</h2></p>
                        </NavLink>
                      </CopyToClipboard> 
                    :
                    <NavLink user={config?.is_not_login == true ? false : true} className={game.isOpenCloudApp ? "iosBox middle" : (game.new_down_type == 2 ? "iosBox roam" :"btn")} onClick={download}>
                      <img src="/resources/game/new-middle-d-n.png" />
                      <p>
                        <h2>下载</h2>
                      </p>
                    </NavLink>} </>
                  : null}

                  {(ceshiNumbei.includes(user?.member_name) || moment(game.starting_time * 1000).format('YYYYMMDD') <= moment().format("YYYYMMDD")) && game.allow_download == 1 ?

                    <>
                    {
                      <>
                        {game.new_down_type != 1 && game.new_down_type !=3 ? 
                          <>
                          {navigator.userAgent.match(/MicroMessenger|QQ[^Browser]+/i) || mobileDetect.userAgents() == 'UCBrowser' ? 
                          <CopyToClipboard text={underUrl} onCopy={() => Dialog.info('已复制链接')}>
                            <NavLink user={config?.is_not_login == true ? false : true} className={game.isOpenCloudApp ? "iosBox rightBut" : "btn"} onClick={() => setVipDownloadSafari(true)}>
                              {game.isOpenCloudApp ?
                              <img src="/resources/game/new-y-d-n-n-q.png" />
                              : 
                              game.new_down_type == 2 ?
                              <img src="/resources/game/ywnnq.png" /> : null}
                              <p><h2>下载</h2></p>
                              </NavLink>
                            </CopyToClipboard> 
                          :
                          <NavLink user={config?.is_not_login == true ? false : true} className={game.isOpenCloudApp ? "iosBox rightBut" : (game.new_down_type == 2 ? "iosBox roam" :"btn")} onClick={download}>
                            {game.isOpenCloudApp ?
                            <img src="/resources/game/new-y-d-n-n-q.png" />
                              : 
                              game.new_down_type == 2 ?
                              <img src="/resources/game/ywnnq.png" /> : null}
                              
                            <p>
                              <h2>下载</h2>
                            </p>
                          </NavLink>} </>
                        : null} 
                        {
                          game.new_down_type != 0 ?
                          <>
                            {mobileDetect.userAgents() == 'Safari' || mb == 1 || (agent == '1eaz' || agent == 'l61u' || agent == '7dgd' || agent == 's7y0' || agent == 'f797' || agent == '9ahi' || agent == '0zme' || agent == '95nv') ? 
                              <NavLink user={config?.is_not_login == true ? false : true} className={(game.isOpenCloudApp || game.new_down_type == 2 ? "iosBox rightBut" : "btn") + (game.new_down_type == 3 ? ' rightButSize ' : '')} onClick={() => {upgrade()}}>

                                {game.new_down_type == 3 ? 
                                  <img src="/resources/game/new-y-d-n-n-q-three.png" />
                                  :
                                  (game.isOpenCloudApp || game.new_down_type == 2 ? 
                                    <img src="/resources/game/new-y-d-n-n-q.png" />
                                  :null)
                                }
                                
                                <p>
                                  <h2>至尊版下载</h2>{game.new_down_type == 2 ? '(推荐)' : ''}
                                </p>
                                
                              </NavLink> 
                              
                              : 
                              <>
                                {mobileDetect.userAgent() == 'WeChat' || mobileDetect.userAgents() == '微信' ? 
                                  <div className={(game.isOpenCloudApp || game.new_down_type == 2 ? "iosBox rightBut" : "btn") + (game.new_down_type == 3 ? ' rightButSize ' : '')} onClick={() => setWeChatImg(true)}>
                                  
                                    {game.new_down_type == 3 ? 
                                      <img src="/resources/game/new-y-d-n-n-q-three.png" />
                                      :
                                      (game.isOpenCloudApp || game.new_down_type == 2 ? 
                                        <img src="/resources/game/new-y-d-n-n-q.png" />
                                      :null)
                                    }
                                    <p>
                                      <h2>至尊版下载</h2>{game.new_down_type == 2 ? '(推荐)' : ''}
                                    </p>
                                    {/* <p>{game.game_size?.ios_size}</p> */}
                                  </div> :
                                  <CopyToClipboard text={underUrl} onCopy={() => Dialog.info('已复制链接')}>
                                    <NavLink user={config?.is_not_login == true ? false : !udid} className={(game.isOpenCloudApp || game.new_down_type == 2 ? "iosBox rightBut" : "btn") + (game.new_down_type == 3 ? ' rightButSize ' : '')} onClick={() => setVipDownloadSafari(true)}>
                                    
                                      {game.new_down_type == 3 ? 
                                        <img src="/resources/game/new-y-d-n-n-q-three.png" />
                                        :
                                        (game.isOpenCloudApp || game.new_down_type == 2 ? 
                                          <img src="/resources/game/new-y-d-n-n-q.png" />
                                        :null)
                                      }
                                      <p>
                                        <h2>至尊版下载</h2>{game.new_down_type == 2 ? '(推荐)' : ''}
                                      </p>
                                      {/* <p>{game.game_size?.ios_size}</p> */}
                                    </NavLink>
                                  </CopyToClipboard> 
                                }
                              </>
                            }
                          </> : null
                        }
                        
                      </>
                    }
                    </>






                  : null
                }

                </> : <>
                  <div className="btn" onClick={download}>
                    <h2>下载</h2>
                    <p>{game.game_size?.android_size}</p>
                  </div>
                </>}

              </>
            }
          </>
          : null}

          {/* game.status == 0  */}
          {
          // game.starting_time > moment().unix() ?
          moment(game.starting_time * 1000).format('YYYYMMDD') > moment().format("YYYYMMDD") ?
            <>
            {game.is_reserved ? <NavLink className="btn btn-reserve btn-reserve-xq" onClick={reserve}>
              <div>取消提醒</div>
            </NavLink>  : 
            // <NavLink className="btn btn-reserve subscribe" onClick={reserve}>
            //   <h2>开启首发提醒</h2>
            //   <p>{moment(game.starting_time * 1000).format('YY-MM-DD HH:mm')}首发</p>
            // </NavLink> 
            <NavLink user={true} className="btn btn-reserve subscribe" onClick={reserve}>
              {/* <h2>开启首发提醒</h2> */}
              <p>{moment(game.starting_time * 1000).format('MM月DD日 HH:mm')}首发</p>
            </NavLink> 
            }
            </>


          : null}
        </div>
        }




        {/* <div className="btns">
          {game.game_species_type == 3
            ? 
            <NavLink onClick={startPlay} iframe={{title: game.game_name}} className="btn btn-yunstartPlay">开始</NavLink>
            : <>
              {mobileDetect.is('iPhone') || mobileDetect.is('iPad') ? <>


                {game.allow_download == 1 ?


                  <>
                  {game.isRealFree ? 
                    <>
                    {
                      <>
                        {mobileDetect.userAgents() == 'Safari' || (agent == '3b8n' || agent == '1eaz' || agent == 'm88y' || agent == '86s0') ? 
                          <NavLink className="btn" onClick={() => setVipDownload(true)}>
                            <h2>至尊版下载</h2>
                            <p>{game.game_size?.ios_size}</p></NavLink> 
                          : <>
                          {mobileDetect.userAgent() == 'WeChat' || mobileDetect.userAgents() == '微信' ? 
                            <div className="btn" onClick={() => setWeChatImg(true)}>
                              <h2>至尊版下载</h2>
                              <p>{game.game_size?.ios_size}</p>
                            </div> :
                            <CopyToClipboard text={underUrl} onCopy={() => Dialog.info('已复制链接')}>
                              <NavLink user={!udid} className="btn" onClick={() => setVipDownloadSafari(true)}>
                                <h2>至尊版下载</h2>
                                <p>{game.game_size?.ios_size}</p>
                              </NavLink>
                            </CopyToClipboard> }</>}
                      </>
                    }</>


                  :


                    <>
                      {game.new_down_type != 1 ? 
                        <>
                        {navigator.userAgent.match(/MicroMessenger|QQ[^Browser]+/i) || mobileDetect.userAgents() == 'UCBrowser' ? 
                        <CopyToClipboard text={underUrl} onCopy={() => Dialog.info('已复制链接')}><NavLink className="btn" onClick={() => setVipDownloadSafari(true)}><h2>下载</h2>{<>&nbsp;<p>{game.game_size?.ios_size}</p></>}</NavLink></CopyToClipboard> 
                        :
                        <NavLink className="btn" onClick={download}>
                          <h2>下载</h2>
                          <p>{game.game_size?.ios_size}</p>
                        </NavLink>} </>
                      : null}
                      {
                        game.new_down_type != 0 ?
                        <>
                          {mobileDetect.userAgents() == 'Safari' || (agent == '3b8n' || agent == '1eaz' || agent == 'm88y' || agent == '86s0') ? 
                            <NavLink className="btn" onClick={() => setVipDownload(true)}>
                              <h2>至尊版下载</h2>
                              <p>{game.game_size?.ios_size}</p></NavLink> 
                            : 
                            <>
                              {mobileDetect.userAgent() == 'WeChat' || mobileDetect.userAgents() == '微信' ? 
                                <div className="btn" onClick={() => setWeChatImg(true)}>
                                  <h2>至尊版下载</h2>
                                  <p>{game.game_size?.ios_size}</p>
                                </div> :
                                <CopyToClipboard text={underUrl} onCopy={() => Dialog.info('已复制链接')}>
                                  <NavLink user={!udid} className="btn" onClick={() => setVipDownloadSafari(true)}>
                                    <h2>至尊版下载</h2>
                                    <p>{game.game_size?.ios_size}</p>
                                  </NavLink>
                                </CopyToClipboard> 
                              }
                            </>
                          }
                        </> : null
                      }
                    </>
                  }
                  </>






                : null
              }

              </> : <>
                <div className="btn" onClick={download}>
                  <h2>下载</h2>
                  <p>{game.game_size?.android_size}</p>
                </div>
              </>}

            </>
          }
          {game.status == 0 ? 
            <>
            {game.is_reserved ? <NavLink className="btn btn-reserve btn-reserve-xq" onClick={reserve}>
              <div>取消提醒</div>
            </NavLink>  : 
            <NavLink className="btn btn-reserve" onClick={reserve}>
              <h2>开启首发提醒</h2>
              <p>{moment(game.starting_time * 1000).format('YY-MM-DD HH:mm')}首发</p>
            </NavLink> 
            }
            </>
          : null}
        </div> */}
        <NavLink onClick={()=>{setAlert(true);Manager.push({event_id:'D-2-22'})}} className="appraise">
          <FormOutlined className="icon"/>
          点评
        </NavLink>
      </div>
    </div>
    <Voucher game={game} show={voucher} setShow={setVoucher}/>
    {/* <CSSTransition in={giftShow} timeout={250} unmountOnExit={true}>
      <div className="gift-detail">
        <div className="gift-mask" onClick={() => setGiftShow(false)}/>
        <div className="gift-container">
          <div className="gift-top">
            <div className="text">
              <div className="name">{gift.packname}</div>
              <div className="time">有效期：{moment(gift.endtime * 1000).format('YYYY-MM-DD')}</div>
            </div>
          </div>
          <div className="gift-main">
            <div className="gift-desc">
              <div className="title">礼包说明</div>
              <div className="main">{gift.packcontent}</div>
            </div>
          </div>
          <NavLink className="gift-bottom" onClick={() => giftReceive(gift)}>领取</NavLink>
        </div>
      </div>
    </CSSTransition> */}
    <CSSTransition in={giftShow} timeout={250} unmountOnExit={true}>
        <div className="gift-detail">
          <div className="gift-mask" onClick={() => setGiftShow(false)}/>
          <div className="gift-container">
            <div className="gift-top">
              <div className="icon">
              <Image src={giftDescInfoImage}/>
              </div>
              <div className="text">
                <div className="name">{gift.gift_name}</div>
                <div className="time">有效期：{moment(gift.gift_dealine * 1000).format('YYYY-MM-DD')}</div>
              </div>
            </div>
            <div className="gift-main">
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"0 0 10px 0"}}>
                <div className="title">礼包说明</div>
                <div className="main">{gift.gift_desc}</div>
              </div>
              <div className="gift-desc" style={{borderBottom:"1px solid #ededed",padding:"10px 0"}}>
                <div className="title">使用说明</div>
                <div className="main">{gift.gift_introduce}</div>
              </div>
            </div>
            <div className="gift-bottom" onClick={()=>giftReceive(gift)}>领取</div>
          </div>
        </div>
      </CSSTransition>
    {giftCode ? <div className="dialog active">
      <div className="dialog-mask"/>
      <div className="dialog-content">
        <div className="dialog-modal">
          <div className="modal-body">
            <div className="modal-title">领取成功</div>
            <div className="modal-content">{giftCode}</div>
          </div>
          <CopyToClipboard text={giftCode} onCopy={() => Dialog.info('已复制')}>
          <div className="modal-btns" onClick={() => setGiftCode(null)}>
            <div className="modal-btn">
                <div >复制</div>
            </div>
          </div>
          </CopyToClipboard>
        </div>
      </div>
    </div> : null}
    {vipDownload ? <VipDownload game={game} setGame={setGame} setPaying={setPaying} setVipDownload={setVipDownload} setPayUrl={setPayUrl} setInterceptState={setInterceptState} setInviteexchangeState={setInviteexchangeState} setOsPopState={setOsPopState}/> : null}

    <CSSTransition in={weChartImg} timeout={250} unmountOnExit={true}>
      <div className="game-wechat-img" ><img className="prompt-img" src="https://app.milu.com/img/weixin/toBrowser.png"/></div>
    </CSSTransition>

    {/* {vipDownloadSafari ? <div className="dialog active">
      <div className="dialog-mask"/>
      <div className="dialog-content">
        <div className="dialog-modal">
          <div className="modal-body">
            <div className="modal-title">复制链接在safari打开</div>
            <div className="modal-content">{url}</div>
          </div>
          <div className="modal-btns">
            <div className="modal-btn">
              <CopyToClipboard text={url} onCopy={() => setCopyBoxImg(true)}>
                <div onClick={() => setVipDownloadSafari(false)}>复制</div>
              </CopyToClipboard>
            </div>
          </div>
        </div>
      </div>
    </div> : null} */}

    <CSSTransition in={paying} timeout={250} unmountOnExit={true}>
      <div className="game-save-pay">
        <div className="mask" onClick={() => setPaying(null)}/>
        <div className="container">
          <div className="title">支付</div>
          <iframe src={payUrl}/>
        </div>
      </div>
    </CSSTransition>

    {vipDownloadSafari ?
      <div >
        <div className="game-wechat-img" onClick={() => setVipDownloadSafari(false)}><img className="prompt-copy-img" src="/resources/game/copybox.png"/></div>
      </div>
    : null}


    {/***实名认证弹框***/}
    {isCheckAuth?
    <div className="pay-check-auth">
        <div className="auth-index">
          <div className="close" onClick={() => setIsCheckAuth(false)}>
            <img src="/resources/user/close.png" />
          </div>
          <div className="title">
            <div className="title-img">
              <img src="/resources/user/auth-title.png" />
            </div>
            <div>
              <h3>实名认证</h3>
              <p>
              {config.authTopTips}
                {/* 根据国家新闻出版署最新规定，游戏用户需要进行实名认证，否则我们无法为您提供游戏及支付服务！ */}
              </p>
            </div>
          </div>

          <div className="auth-user-realname">
            <div className="auth-user-input">
              <div className="name">真实姓名</div>
              <input name="nick_name" type="text" placeholder="请输入姓名" autoComplete="off" value={authData.real_name} onChange={e => setAuthData({...authData, real_name: e.target.value})} />
            </div>
            <div className="auth-user-input">
              <div className="name">身份证号</div>
              <input name="number" type="text" placeholder="请输入身份证号" autoComplete="off" value={authData.identity_card} onChange={e => setAuthData({...authData, identity_card: e.target.value})} />
            </div>
            <span>
            {config.authBottomTips}
            {/* 您所提交的身份信息仅用于国家认证，我们将按照国家隐私条例严格保密信息，感谢您的支持！ */}
            </span>
            <div className="auth-user-btn" onClick={certifiy}>立即认证</div>
          </div>
        </div>
      </div> : null
      }
      {/** 提示弹框 **/}
      {
      isCheckAdult ? 
      <div className="pay-check-auth">
        <div className="auth-index adult-index">
          <div className="title">
            <div className="title-img">
              <img src="/resources/user/auth-title.png" />
            </div>
            <div>
              <h3>温馨提示</h3>
              <p>
              {config.adultTips}
                {/* 根据国家新闻出版署最新规定，禁止向未成年用户提供支付服务。 */}
              </p>
            </div>
          </div>
          <div className="auth-user-realname">
            <div className="auth-user-btn" onClick={()=>setIsCheckAdult(false)}>我知道了</div>
          </div>
        </div>
      </div>
      : null
      }

      {/**下载小贴士start */}
      {downTipsState ? 
      <div className="m-down-type" >
        <div className="bg"></div>
        <div className="inner">
            <div className="down-top">
              <div className="m-down-wp">
                <div className="g-t1">
                    <div className="t-left-jt-close" onClick={() => setDownTipsState(false)}><img src="/resources/user/close.png" /></div>
                    {/* <h3 className="tit">下载小贴士</h3> */}
                </div>
              </div>
            </div>
            <div>
                <div className="m-down-wp">
                    {/* <p style="margin-top: .3rem;">1.统一安装后，将自动在桌面上开始安装</p> */}
                    <div className="m-down-top">
                      <div>游戏打包中请稍后，待弹出安装后即可安装!</div>
                      <div style={{color:'red'}}>游戏安装完成后需根据以下步骤授权</div>
                    </div>
                    <p>1.打开设置，选择通用-VPN与设备管理</p>
                    <img className="m-icon" src="resources/1.png" alt="" />
                    <p>2.找到游戏对应的授权，点击信任证书</p>
                    <img className="m-icon" src="resources/2.png" alt="" />
                    {
                      mobileDetect.versionStr('iPhone').split("_").includes("18") == true && <div>
                      <p>3.允许并重新启动后点击安装描述文件即可
                        <span style={{color:'red'}}>（iOS18需操作此步骤）</span>
                      </p>
                      <img style={{paddingBottom:'30px'}} className="m-icon" src="resources/3.png" alt="" />
                      </div>
                    }
                    {/* <button type="button" className="trust" onClick={() => setDownTipsState(false)}>我知道了</button> */}
                </div>
            </div>
        </div>
      </div>
      :null}
      {/**下载小贴士end */}

      {
        trumpetState ?
        <div className="game-trumpet-index">
          <div className="trumpet-site">
            <div className="trumpet-main">
              {/* <div className="close" onClick={() => setExchangeState(false)}>
                <img src="/resources/user/close.png" />
              </div> */}
                <div className="trumpet-title">
                温馨提示
                </div>
                <div className="info">
                  <p>您游戏中尚未创建小号，暂时无法领取此礼包</p>
                </div>
                <div className="trumpet-btn">
                  <div className="item btn-about" onClick={()=>{setTrumpetState(false)}}>
                    稍后再领
                  </div>
                  {game.game_species_type == 3
                  ? 
                  <NavLink onClick={()=>{setGiftShow(false);setTrumpetState(false);startPlay()}} iframe={{title: game.game_name}} className="item btn-confirm">进入游戏</NavLink>
                  :<>
                  {mobileDetect.is('iPhone') || mobileDetect.is('iPad') ? <>
                  {game.new_down_type != 0 ?
                  <>
                    {mobileDetect.userAgents() == 'Safari' || (agent == '1eaz' || agent == '7dgd' || agent == 's7y0') ? 
                      <NavLink user={!udid} className="item btn-confirm" onClick={() => {setVipDownload(true);setGiftShow(false);setTrumpetState(false);}}>
                        <h2>下载游戏</h2>
                      </NavLink>
                      : <>
                      {mobileDetect.userAgent() == 'WeChat' || mobileDetect.userAgents() == '微信' ? 
                        <div className="item btn-confirm" onClick={() => {setWeChatImg(true);setGiftShow(false);setTrumpetState(false);}}>
                          <h2>下载游戏</h2>
                        </div> :
                        <CopyToClipboard text={underUrl} onCopy={() => Dialog.info('已复制链接')}>
                          <NavLink user={!udid} className="item btn-confirm" onClick={() => {setVipDownloadSafari(true);setGiftShow(false);setTrumpetState(false);}}>
                            <h2>下载游戏</h2>
                          </NavLink>
                        </CopyToClipboard> }</>}
                  </> : null}
                  </> : 
                  <>
                  <div className="item btn-confirm" onClick={download}>
                    <h2>下载游戏</h2>
                  </div>
                  </>}
                  </>}
                </div>
            </div>
          </div>
        </div>
        : null
      }

      {/** start **/}
      <CSSTransition in={startRoamState} timeout={250} unmountOnExit={true}>
      <div className="game-roam-detail">
        <div className="roam-mask" />
        <div className="roam-container">
          <div className="roam-top">
            <div className="close" onClick={()=>setStartRoamState(false)}>
              <img src="/resources/party/couple-close.png" />
            </div>
          </div>

          <div className="roam-tips">
            云游戏说明
          </div>
          <div className="roam-tips2">
            <p>1.免费云游，极速畅玩；</p>
            <p>2.无需签名，无需付费无需下载等待；</p>
            {/* <p>3.玩的游戏找不到了？首页点击icon云玩记录即可查看云玩历史进入游戏。</p> */}
            <p>3.添加到桌面后，即能直接从手机桌面进入。</p>
            {
              game?.game_info?.is_both == 0 ?
              <p style={{color:'red',fontSize:'.24rem',fontWeight:'bold'}}>注：云游所产生的数据属于安卓端，无法与IOS数据互通；若您之前在IOS游戏包有角色，请继续下载IOS游戏包进行游戏。</p>
              : null
            }
          </div>

          {/** gamefullscreen 是否隐藏title的标识 **/}

          {
            game.cloudappIndexUrl ? 
              // <NavLink className="roam-bottom"
              //   to={(()=>{
              //     let url = URI(game.cloudappIndexUrl)
              //       .addQuery('gameid', game.maiyou_gameid)
              //       .addQuery('username', localStorage.getItem('username'))
              //       .addQuery('token', localStorage.getItem('token'))
              //       .addQuery('userid', localStorage.getItem('user_id'))
              //       .addQuery('source', 'react')
              //       .addQuery('origin', 'react')
              //       .addQuery('time', moment().unix())
              //       .addQuery('location_url', encodeURIComponent(window.location.origin + '/#/game?id='+params.id))
              //       .toString();
              //     return url;
              //   })()}
              //   iframe={{title: '云玩', gamefullscreen:'1'}}
              //   onClick={()=>setStartRoamState(false)}>
              //   立即开始
              // </NavLink>

              <div className="operate-cloud-app">
              {/* <NavLink className="roam-bottom"
                to={(()=>{
                  let url = URI(game.cloudappIndexUrl)
                    .addQuery('gameid', game.maiyou_gameid)
                    .addQuery('username', localStorage.getItem('username'))
                    .addQuery('token', localStorage.getItem('token'))
                    .addQuery('userid', localStorage.getItem('user_id'))
                    .addQuery('source', 'react')
                    .addQuery('origin', 'react')
                    .addQuery('time', moment().unix())
                    .addQuery('gameicon', game.game_image?.thumb)
                    .addQuery('gamename', game.game_name)
                    .addQuery('nameremark', game.nameRemark)
                    .addQuery('location_url', encodeURIComponent(window.location.origin + '/#/game?id='+params.id))
                    .toString();
                  return url;
                })()}
                iframe={{title: '云玩', gamefullscreen:'1'}}
                onClick={()=>setStartRoamState(false)}>
                立即开始
              </NavLink> */}

              <div className="roam-bottom daskapp-game" onClick={()=>isDeskCloudApp()}>
                添加即玩
              </div>
              </div>
            : null
          }
          {/* <NavLink className="roam-bottom"
            to={(()=>{
              let url = URI(config.cloudappIndexUrl)
                .addQuery('gameid', game.maiyou_gameid)
                .addQuery('username', localStorage.getItem('username'))
                .addQuery('token', localStorage.getItem('token'))
                .addQuery('userid', localStorage.getItem('user_id'))
                .addQuery('source', 'react')
                .addQuery('origin', 'react')
                .addQuery('location_url', encodeURIComponent(window.location.origin + '/#/game?id='+params.id))
                .toString();
              return url;
            })()}
            iframe={{title: '云玩', gamefullscreen:'1'}}
            onClick={()=>setStartRoamState(false)}>
            立即开始
          </NavLink> */}
        </div>
      </div>
      </CSSTransition>

      <CSSTransition in={customizeState} timeout={250} unmountOnExit={true}>
        <div className="game-customize-detail">
          <div className="customize-mask" />
          <div className="customize-container">
            <div className="customize-top">
              <div className="close" onClick={()=>setCustomizeState(false)}>
                <img src="/resources/party/couple-close.png" />
              </div>
            </div>

            <div className="customize-tips">
              {game?.jumpBtnInfo?.popTitle}
            </div>
            <div className="customize-tips2">
              <div className="entity" dangerouslySetInnerHTML={{__html: game?.jumpBtnInfo?.popContent ? game.jumpBtnInfo.popContent : null}}/>
            </div>
            <div className="operate-cloud-app">
              <div className="customize-bottom daskapp-game" onClick={()=>customizeJump()}>
                {game?.jumpBtnInfo?.popBtnTitle}
              </div>
              {game?.jumpBtnInfo?.downloadNow ? 
              <NavLink className="customize-bottom-download" onClick={() => {setCustomizeState(false);upgrade()}}>
                直接下载
              </NavLink>
              : null}
            </div>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={waitRoamState} timeout={250} unmountOnExit={true}>
      <div className="game-roam-detail">
        <div className="roam-mask" />
        <div className="roam-container">
          <div className="roam-top">
            <div className="close" onClick={()=>setWaitRoamState(false)} >
              <img src="/resources/party/couple-close.png" />
            </div>
          </div>

          <div className="roam-tips">
            云游戏说明
          </div>
          <div className="roam-tips2">
            <p>1.免费云游，极速畅玩；</p>
            <p>2.无需签名，无需付费无需下载等待。</p>
          </div>
          <div className="roam-tips3">
            排队中...
          </div>
          <div className="roam-tips4">
            如需更稳定流畅的体验，推荐下载至尊版。
          </div>
          <div className="roam-bottom-download" onClick={() => {upgrade()}}>
            至尊版下载
          </div>
          <div className="roam-waiting" onClick={()=>setWaitRoamState(false)}>继续等待</div>
        </div>
      </div>
      </CSSTransition>
      
      <CSSTransition in={upgradeState} timeout={250} unmountOnExit={true}>
      <div className="system-upgrade-notice">
        <div className="upgrade-mask" />
        <div className="upgrade-container">
          <img src="/resources/game/system-up-tips.png" />
          <div className="upgrade-main">
            <div className="upgrade-top">
              因您的系统升级为iOS16，现按照iOS官方要求，需开启开发者模式才可进入游戏
            </div>
            <div className="tips">
              开启流程如下
            </div>
            <img src="/resources/game/system-up-main.png" />
            <div className="upgrade-bottom-download" onClick={()=>{setVipDownload(true);setUpgradeState(false)}}>
              我知道了
            </div>
          </div>
        </div>
      </div>
      </CSSTransition>

      <CSSTransition in={serverShow} timeout={250} unmountOnExit={true}>
        <div className="server-alert">
          <div className="server-mask" onClick={()=>setServerShow(false)}></div>
          <div className="server-container">
            <div style={{margin:'0 auto',fontSize:'0.34rem',color:'#282828',padding:'10px',fontWeight:'bold'}}>开服表</div>
            <Server></Server>
            <CloseOutlined className="server-close" onClick={()=>setServerShow(false)}></CloseOutlined>
          </div>
        </div>
      </CSSTransition>

      <CSSTransition in={alert} timeout={250} unmountOnExit={true}>
          <div className="publish-alert">
            <div className="publish-mask" onClick={()=>setAlert(false)}></div>
            <div className="publish-container">
              <div className="header">
                <NavLink to={URI('https://api3.app.wakaifu.com/communityRewards').toString()}
                  iframe={{title: '奖励规则'}}
                  user={false}
                >
                  <div className="rule">
                    <img src="resources/community/rule.png" alt="" />
                    &nbsp;<span>奖励规则</span>
                  </div>
                </NavLink>
                
                <div className="close" onClick={()=>setAlert(false)}>
                  <img src="resources/community/close.png" alt="" />
                </div>
              </div>
              <div className="content">
                <NavLink to={'/publish?tagid=1&topic_id=' + game.game_info?.maiyou_gameid}>
                  <div className="item" onClick={()=>setAlert(false)}>
                    <img className='icon' src="resources/community/leave_comment.png" alt="" />
                    <img className='logo' src="resources/community/prizes.png" alt="" />
                    <div className="text">发评论</div>
                  </div>
                </NavLink>
                <NavLink 
                  to={game?.played ? '/publish?tagid=2&topic_id=' + game.game_info?.maiyou_gameid : ''}
                  onClick={()=>{
                    if (!game?.played){
                      Dialog.info('只能玩过该游戏才能发布攻略！')
                    }
                  }}>
                  <div className="item" onClick={()=>setAlert(false)}>
                    <img className='icon' src="resources/community/leave_strategy.png" alt="" />
                    <img className='logo' src="resources/community/prizes.png" alt="" />
                    <div className="text">发攻略</div>
                  </div>
                </NavLink>
                <NavLink to={'/publish?tagid=3&topic_id=' + game.game_info?.maiyou_gameid}>
                  <div className="item" onClick={()=>setAlert(false)}>
                    <img className='icon' src="resources/community/leave_question.png" alt="" />
                    <div className="text">问大神</div>
                  </div>
                </NavLink>
              </div>
              <div className="cancel" onClick={()=>setAlert(false)}>取消</div>
            </div>
          </div>
        </CSSTransition>
      {/** 动态进度 **/}
      {/* <CSSTransition in={testProgress} timeout={250} unmountOnExit={true}>
      <div className="game-progress-bar">
        <div className="bar-mask" />
        <div className="bar-container">
          <div className="bar-top">
            <div className="name" onClick={()=>{setGameExitConfirm(true)}}>取消</div>
          </div>

          <div className="bar-game-info">
            <div className="icon">
              <img src={game.game_image?.thumb}/>
            </div>
            <div className="name">
              {game.game_name}
            </div>
          </div>

          <div className="bar-gray">
            <div className="bar-progress" style={{width: progressNum + "%"}}></div>
            <span>下载中，勿退出{progressNum}%</span>
          </div>

          <div className="bar-tips">
            <div className="icon">
              <img src="/resources/game/game-question-mark.png"/>
            </div>
            <div className="name">
              请不要离开此页面并保持屏幕常亮 
            </div>
          </div>

          <div className="bar-udid">
            <span>UDID：{udid}</span>
            <CopyToClipboard text={udid} onCopy={() => Dialog.info('已复制')}><div className="copy-udid">复制</div></CopyToClipboard>
          </div>
        </div>
      </div>
      </CSSTransition> */}

      {/** end **/}

      {interceptState ? 
      <div className="game-udidxz-index">
        <div className="exit-site">
          <div className="exit-main">
            <div className="exit-title">
            温馨提示
            </div>
            <div className="info">
              <p>因苹果官方策略调整，您的设备UDID已经提交审核，预计审核期限为3天，审核通过之后即能直接安装游戏包，在此期间请您先进行云玩！</p>
            </div>
            <div className="exit-btn">
              <div className="item btn-about" onClick={() => {setInterceptState(false)}}>
                知道了
              </div>
              <div className="item btn-confirm" onClick={() => {setInterceptState(false);isroam()}}>
                去云玩
              </div>
            </div>
          </div>
        </div>
      </div>
      : null}
      {osPopState ?
        <VersionMask></VersionMask>
      : null}

  </div> : null

{/* <div className="game-detail" ref={ref}>
<div className="game-skeleton">
    <div className="a"></div>
    <div className="b"></div>
</div>
</div> */}
}