import React, { useEffect, useState } from 'react'
import './css/signgift.css'
import general from '../../../general';
import Dialog from '../../../lib/Dialog';
import Image from '../../../lib/Image';
import Subtitle from '../../components/Subtitle';
import SignAlert from '../gamesign/SignAlert';
import { NavLink } from '../../../lib/Navigation';
import { useScroll } from 'ahooks';
import $ from 'jquery'

export default function SignGift(props) {

  const [tag_id, settag_id] = useState('')
  const [data, setData] = useState()
  const [init, setInit] = useState(false)
  const [visible, setVisible] = useState(false)
  const [gift, setGift] = useState()
  const [more,setMore] = useState(false)
  const [page,setPage] = useState(1)
  const [game_list, setgame_list] = useState([])
  const [load, setLoad] = useState(false)

  const getData = (loading,tag,pages) => {
    if (load){
      return
    }
    setLoad(true)
    if (loading){
      Dialog.loading()
    }
    general.axios.post('/base/signbonus/getSignGiftList', {
      id: props.query.id,
      tag_id:tag,
      pagination:{
        page:pages,
        count:20
      }
    }).finally(() => {
      Dialog.close()
    }).then(res => {
      const { data, status } = res.data
      if (status.succeed) {
        setLoad(false)
        setData(data)
        setInit(true)
        setgame_list(() => pages == 1 ? res.data.data.game_list : [...game_list, ...res.data.data.game_list]);
        setPage(pages)
        let {paginated} = data;
        setMore(paginated.more);
      } else {
        Dialog.error(status.error_desc)
      }
    })
  }

  useEffect(() => {
    if (props.active) {
      getData(!init,tag_id,1)
      props.setOperate(<NavLink to='/user/gift'>我的礼包</NavLink>)
    }
  }, [props.active])

  const scroll = useScroll()
  useEffect(()=>{
    if (scroll.top > 40){
      props.setTitle('签到礼包')
    }else {
      props.setTitle('')
    }
    if (scroll.top < -80) {
      getData(true,tag_id,1);
    }
    if (scroll.top + window.innerHeight + 20 > $(document).height() && more) {
      getData(true,tag_id, page + 1);
    }
  },[scroll])

  const handleTag = (item) => {
    settag_id(item.id)
    getData(true,item.id,1)
  }

  const sign = (item)=>{
    if (item.isTodaySign){
      return
    }
    Dialog.loading()
    general.axios.post('/base/signbonus/sign', {
      topic_id: item.maiyou_gameid,
    }).finally(()=>{
      Dialog.close()
    }).then(res => {
      const { status } = res.data
      if (status.succeed) {
        setVisible(true)
        setGift(res.data.data)
        data.play_list.map(obj=>{
          if (obj.id == item.id){
            obj.isTodaySign = true
          }
        })
        setData({...data})
      } else {
        Dialog.error(status.error_desc)
      }
    })
  }

  return (
    <div className='sign-gift'>
      {data && <div className='sign-gift-container'>
        <img src="resources/game/sign/title.png" alt="" />
        <div className="games">
          {data?.play_list.length > 0 && <div className="playing">
            <div className='sign-gift-title'>我玩过的游戏</div>
            <div className="game">
              {data?.play_list.map(item => {
                return (
                  <div  className='item'>
                    <NavLink user={false} to={`/game?id=${item.game_id}`}>
                      <img src={item.game_image.thumb} height={51} alt=""/>
                    </NavLink>
                    <NavLink user={false} to={`/gamesign?id=${item.maiyou_gameid}`} className='content'>
                      <div className="name">{item.game_name}</div>
                      <div className="desc">{item.game_classify_type.trim()} · {item.nameRemark}</div>
                    </NavLink>
                    <NavLink className={"btn " + (item.isTodaySign ? 'disable' : '')} onClick={()=>sign(item)}>{item.isTodaySign ? '今日已签' : '去签到'}</NavLink>
                  </div>
                )
              })}
            </div>
          </div>}
          <div className="allgame">
            <div className="sign-gift-title">全部游戏</div>
            <div className="tags">
              {data?.tag.map(item => {
                return (
                  <div key={item.id} onClick={() => handleTag(item)} className={'tag ' + (tag_id == item.id ? 'active' : '')}>
                    <div className="name">{item.tagname}</div>
                  </div>
                )
              })}
            </div>
            <div className="list">
              {game_list.map(game => {
                return (
                  <NavLink user={false} to={`gamesign?id=${game.maiyou_gameid}`} className='item'>
                    <div className="game">
                      <NavLink user={false} to={`/game?id=${game.game_id}`}>
                        <Image className="icon" src={game.game_image.thumb} />
                      </NavLink>
                      <div className="text">
                        <div className="name">{game.game_name}</div>
                        <div className="info1">
                          {game.game_classify_type.trim()}
                          <Subtitle>{game.nameRemark}</Subtitle>
                        </div>
                        <div className="info3">
                          {
                            game.bottom_lable ?
                              <>
                                {Object.keys(game.bottom_lable).length > 0 ?
                                  <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <span className="first-text" style={{ background: game.bottom_lable.type == 1 ? '#FF8C50' : '#9F9DFC' }}>{game.bottom_lable.left_content}</span><span className="second-text" style={{ color: game.bottom_lable.type == 1 ? '#FF8C50' : '#9F9DFC', borderColor: game.bottom_lable.type == 1 ? '#FF8C50' : '#9F9DFC' }}>{game.bottom_lable.right_content}</span>
                                  </div> : null}
                              </>
                              : null
                          }
                          {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
                          {game.is_support_accele ? <div className="speed">可加速</div> : null}
                          {game.is_easy_play ? <div className="speed">省心玩</div> : null}
                          {Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play && game.introduction && game.exclusive_game_describe?.length == 0 ? <span style={{ color: '#888' }}>{game.introduction}</span> : null}
                        </div>
                      </div>
                      <div className="btn">去签到</div>
                    </div>
                    <div className="gift">
                      <img className='icon' src="resources/game/sign/7天.png" alt="" />
                      <span className='number'>{game.sign_gift_number}</span>
                      <span className='text'>{game.sign_gift_text}</span>
                    </div>
                  </NavLink>
                )
              })}
            </div>
          </div>
        </div>
        {game_list?.length > 0 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
      </div>}
      {visible && <SignAlert gift={gift} visible={visible} action={() => setVisible(false)}></SignAlert>}
    </div>
  )
}
